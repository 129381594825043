import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SlimTemplate from 'pages/slimTemplate';
import useStyles from './styles';

const Terms = () => {
  const classes = useStyles();

  return (
    <SlimTemplate showMenu title="Terms and Conditions">
      <Paper className={classes.documentWrapper}>
        <Typography variant="h4" className={classes.formTitle}>
          Terms and Conditions
        </Typography>
        <Typography gutterBottom variant="h6">
          Last updated: May 01, 2024
        </Typography>
        <Typography paragraph>
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the{' '}
          <Link to="https://utto.io">cloud.utto.io</Link> website/s and any UTTO
          Mobile applications (together, or individually, the "Service")
          operated by IPEG Corporation ("us", "we", or "our").
        </Typography>
        <Typography paragraph>
          Your access to and use of the Service is conditioned upon your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who wish to access or use the Service.
        </Typography>
        <Typography paragraph>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you do not have
          permission to access the Service.
        </Typography>
        <Typography gutterBottom variant="h6">
          Purchases
        </Typography>
        <Typography paragraph>
          If you wish to purchase any product or service made available through
          the Service ("Purchase"), you may be asked to supply certain
          information relevant to your Purchase including, without limitation,
          your credit card number, the expiration date of your credit card, your
          billing address, and your shipping information.
        </Typography>
        <Typography paragraph>
          You represent and warrant that: (i) you have the legal right to use
          any credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </Typography>
        <Typography paragraph>
          The service may employ the use of third party services for the purpose
          of facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.
        </Typography>
        <Typography paragraph>
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to: product or service availability,
          errors in the description or price of the product or service, error in
          your order or other reasons.
        </Typography>
        <Typography paragraph>
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.
        </Typography>
        <Typography gutterBottom variant="h6">
          Availability, Errors and Inaccuracies
        </Typography>
        <Typography paragraph>
          We are constantly updating product and service offerings on the
          Service. We may experience delays in updating information on the
          Service and in our advertising on other web sites. The information
          found on the Service may contain errors or inaccuracies and may not be
          complete or current. Products or services may be mispriced, described
          inaccurately, or unavailable on the Service and we cannot guarantee
          the accuracy or completeness of any information found on the Service.
        </Typography>
        <Typography paragraph>
          We therefore reserve the right to change or update information and to
          correct errors, inaccuracies, or omissions at any time without prior
          notice.
        </Typography>
        <Typography gutterBottom variant="h6">
          Subscriptions
        </Typography>
        <Typography paragraph>
          Some parts of the Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles are set either on a
          monthly or annual basis, depending on the type of subscription plan
          you select when purchasing a Subscription.
        </Typography>
        <Typography paragraph>
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or IPEG
          Corporation cancels it. You may cancel your Subscription renewal
          either through your online account management page or by contacting
          IPEG Corporation customer support team.
        </Typography>
        <Typography paragraph>
          A valid payment method, including credit card or PayPal, is required
          to process the payment for your Subscription. You shall provide IPEG
          Corporation with accurate and complete billing information including
          full name, address, state, zip code, telephone number, and a valid
          payment method information. By submitting such payment information,
          you automatically authorize IPEG Corporation to charge all
          Subscription fees incurred through your account to any such payment
          instruments.
        </Typography>
        <Typography paragraph>
          Should automatic billing fail to occur for any reason, IPEG
          Corporation will issue an electronic invoice indicating that you must
          proceed manually, within a certain deadline date, with the full
          payment corresponding to the billing period as indicated on the
          invoice.
        </Typography>
        <Typography gutterBottom variant="h6">
          Free Trial
        </Typography>
        <Typography paragraph>
          IPEG Corporation may, at its sole discretion, offer a Subscription
          with a free trial for a limited period of time ("Free Trial").
        </Typography>
        <Typography paragraph>
          You may be required to enter your billing information in order to sign
          up for the Free Trial.
        </Typography>
        <Typography paragraph>
          If you do enter your billing information when signing up for the Free
          Trial, you will not be charged by IPEG Corporation until the Free
          Trial has expired. On the last day of the Free Trial period, unless
          you cancelled your Subscription, you will be automatically charged the
          applicable Subscription fees for the type of Subscription you have
          selected.
        </Typography>
        <Typography paragraph>
          At any time and without notice, IPEG Corporation reserves the right to
          (i) modify the terms and conditions of the Free Trial offer, or (ii)
          cancel such Free Trial offer.
        </Typography>
        <Typography gutterBottom variant="h6">
          Fee Changes
        </Typography>
        <Typography paragraph>
          IPEG Corporation, in its sole discretion and at any time, may modify
          the Subscription fees for the Subscriptions. Any Subscription fee
          change will become effective at the end of the then-current Billing
          Cycle.
        </Typography>
        <Typography paragraph>
          IPEG Corporation will provide you with a reasonable prior notice of
          any change in Subscription fees to give you an opportunity to
          terminate your Subscription before such change becomes effective.
        </Typography>
        <Typography paragraph>
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </Typography>
        <Typography gutterBottom variant="h6">
          Refunds
        </Typography>
        <Typography paragraph>
          Certain refund requests for Subscriptions may be considered by IPEG
          Corporation on a case-by-case basis and granted in sole discretion of
          IPEG Corporation.
        </Typography>
        <Typography gutterBottom variant="h6">
          Accounts
        </Typography>
        <Typography paragraph>
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on the Service.
        </Typography>
        <Typography paragraph>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </Typography>
        <Typography paragraph>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization. You may not use as a username any
          name that is offensive, vulgar or obscene.
        </Typography>
        <Typography paragraph>
          We reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </Typography>
        <Typography gutterBottom variant="h6">
          Intellectual Property
        </Typography>
        <Typography paragraph>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of IPEG Corporation and its
          licensors. The Service is protected by copyright, trademark, and other
          laws of both the United States and foreign countries. Our trademarks
          and trade dress may not be used in connection with any product or
          service without the prior written consent of IPEG Corporation.
        </Typography>
        <Typography gutterBottom variant="h6">
          Links To Other Web Sites
        </Typography>
        <Typography paragraph>
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by IPEG Corporation
        </Typography>
        <Typography paragraph>
          IPEG Corporation has no control over, and assumes no responsibility
          for the content, privacy policies, or practices of any third party web
          sites or services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </Typography>
        <Typography paragraph>
          You acknowledge and agree that IPEG Corporation shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such third party web sites or services.
        </Typography>
        <Typography paragraph>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third party web sites or services that you visit.
        </Typography>
        <Typography gutterBottom variant="h6">
          Termination
        </Typography>
        <Typography paragraph>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </Typography>
        <Typography paragraph>
          If you wish to terminate your account, you may simply discontinue
          using the Service.
        </Typography>
        <Typography paragraph>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </Typography>
        <Typography gutterBottom variant="h6">
          Indemnification
        </Typography>
        <Typography paragraph>
          You agree to defend, indemnify and hold harmless IPEG Corporation and
          its licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password, or b) a breach of these Terms.
        </Typography>
        <Typography gutterBottom variant="h6">
          Limitation Of Liability
        </Typography>
        <Typography paragraph>
          In no event shall IPEG Corporation, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </Typography>
        <Typography gutterBottom variant="h6">
          Disclaimer
        </Typography>
        <Typography paragraph>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </Typography>
        <Typography paragraph>
          IPEG Corporation its subsidiaries, affiliates, and its licensors do
          not warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </Typography>
        <Typography gutterBottom variant="h6">
          Exclusions
        </Typography>
        <Typography paragraph>
          Some jurisdictions do not allow the exclusion of certain warranties or
          the exclusion or limitation of liability for consequential or
          incidental damages, so the limitations above may not apply to you.
        </Typography>
        <Typography gutterBottom variant="h6">
          Governing Law
        </Typography>
        <Typography paragraph>
          These Terms shall be governed and construed in accordance with the
          laws of Florida, United States, without regard to its conflict of law
          provisions.
        </Typography>
        <Typography paragraph>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have had between
          us regarding the Service.
        </Typography>
        <Typography gutterBottom variant="h6">
          Changes
        </Typography>
        <Typography paragraph>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will provide at
          least 30 days notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </Typography>
        <Typography paragraph>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use the
          Service.
        </Typography>
        <Typography gutterBottom variant="h6">
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about these Terms, please contact us.
        </Typography>
        <Typography paragraph>
          IPEG Corporation
          <br />
          5400 Jaeger Road
          <br />
          Naples, Florida 34109 U.S.A
        </Typography>
        <Typography paragraph>
          <a href="tel:+1-239-963-1470">(239) 963 1470</a>
          <br />
          <a href="mailto:info@ipegcorp.com">info@ipegcorp.com</a>
        </Typography>
      </Paper>
    </SlimTemplate>
  );
};

export default Terms;
