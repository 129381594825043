import { combineReducers } from 'redux';
import { types as authTypes } from 'ducks/auth/actions';

// create a reducer given initial state and reducers for that state
export const createReducer = (initialState, reducers) => (
  state = initialState,
  action
) => reducers[action.type]?.(state, action) || state;

export const combineReducersWithTeamChangeReset = reducers => {
  const combinedReducers = combineReducers(reducers);

  const teamChangeResetWrapper = (state, action) => {
    if (
      action.type === authTypes.changeCurrentTeam ||
      action.type === authTypes.changeCurrentDivision
    ) {
      return combinedReducers(undefined, action);
    } else {
      return combinedReducers(state, action);
    }
  };

  return teamChangeResetWrapper;
};

export const combineReducersWithDivisionChangeReset = reducers => {
  const combinedReducers = combineReducers(reducers);

  const divisionChangeResetWrapper = (state, action) => {
    if (action.type === authTypes.changeCurrentDivision) {
      return combinedReducers(undefined, action);
    } else {
      return combinedReducers(state, action);
    }
  };

  return divisionChangeResetWrapper;
};

export const FETCH_DATA_INITIAL_STATE = {
  pending: false,
  error: null,
  data: null,
  params: null
};

export const POST_DATA_INITIAL_STATE = {
  success: false,
  pending: false,
  error: null,
  data: null
};

export const generateFetchDataTypes = prefix => ({
  REQUEST: `${prefix}_REQUEST`,
  SUCCESS: `${prefix}_SUCCESS`,
  FAILURE: `${prefix}_FAILURE`,
  CLEAR: `${prefix}_CLEAR`
});

export const generatePostDataTypes = generateFetchDataTypes;

export const generateFetchDataActions = types => ({
  request: payload => ({
    type: types.REQUEST,
    payload: payload
  }),
  success: payload => ({
    type: types.SUCCESS,
    payload: payload
  }),
  failure: payload => ({
    type: types.FAILURE,
    payload: payload
  }),
  clear: () => ({
    type: types.CLEAR
  })
});

export const generatePostDataActions = generateFetchDataActions;

export const generateFetchDataReducers = types => ({
  [types.REQUEST]: (state, action) => ({
    ...state,
    pending: true,
    error: null,
    params: action.payload
  }),
  [types.SUCCESS]: (state, action) => ({
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }),
  [types.FAILURE]: (state, action) => ({
    ...state,
    pending: false,
    error: action.payload
  }),
  [types.CLEAR]: state => ({
    ...state,
    error: null,
    data: null,
    pending: false
  }),
  [authTypes.LOGOUT]: () => FETCH_DATA_INITIAL_STATE
});

export const generatePostDataReducers = (types, ignoreData = false) => ({
  [types.REQUEST]: state => ({
    ...state,
    pending: true,
    success: false,
    error: null
  }),
  [types.SUCCESS]: (state, action) => ({
    pending: false,
    success: true,
    error: null,
    data: ignoreData ? null : action.payload
  }),
  [types.FAILURE]: (state, action) => ({
    pending: false,
    success: false,
    error: action.payload,
    data: null
  }),
  [types.CLEAR]: () => POST_DATA_INITIAL_STATE,
  [authTypes.LOGOUT]: () => POST_DATA_INITIAL_STATE
});
