import { all, put, debounce, select, call } from 'redux-saga/effects';
import {
  types,
  orgsActions,
  divsActions,
  teamsActions,
  membersActions,
  managersActions,
  newOrgActions,
  newDivActions,
  newTeamActions,
  newUserActions,
  reprocessUploadActions,
  deleteUploadActions
} from 'ducks/admin/actions';
import {
  postOrg,
  postDiv,
  postTeam,
  postUser,
  postRole,
  deleteRole,
  fetchOrgs,
  fetchDivs,
  fetchTeams,
  fetchMembers,
  fetchManagers,
  fetchUsers,
  editUser,
  editUserAccountSuspension,
  editUserVrs,
  postSupportForm,
  fetchUtilityTypes,
  putUtilityType,
  fetchUnprocessedUploads,
  reprocessUpload,
  annihilateUpload,
  fetchUploads,
  triggerRefresh,
  fetchSingleUpload
} from 'services/api';
import { PERMISSIONS } from 'config';
import { getOrg, getPermissions } from 'ducks/auth/selectors';
import { fetchDataWorkerSaga } from 'sagas/util';

function* createOrg(action) {
  try {
    const res = yield postOrg(action.payload);
    yield put(newOrgActions.success(res));
  } catch (err) {
    yield put(newOrgActions.failure(err.message));
  }
}

function* createDiv(action) {
  try {
    const res = yield postDiv(action.payload);
    yield put(newDivActions.success(res));
  } catch (err) {
    yield put(newDivActions.failure(err.message));
  }
}

function* createTeam(action) {
  try {
    const res = yield postTeam(action.payload);
    yield put(newTeamActions.success(res));
  } catch (err) {
    yield put(newTeamActions.failure(err.message));
  }
}

function* createUser(action) {
  try {
    const res = yield postUser(action.payload);
    // we only get an {id} back from the server
    const newUser = {
      ...res,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      email: action.payload.email.toLowerCase(),
      phone: action.payload.phone
    };
    yield put(newUserActions.success(newUser));
  } catch (err) {
    yield put(newUserActions.failure(err.message));
  }
}
// TODO proper handling of failed role allocation
function* createRole(action) {
  try {
    yield postRole(action.payload);
    yield put(membersActions.request(action.payload)); //quick hack, should use optimistic ui instead (TODO)
    yield put(managersActions.request(action.payload)); //quick hack, should use optimistic ui instead (TODO)
  } catch (err) {
    console.log(err);
  }
}

function* removeRole(action) {
  try {
    yield deleteRole(action.payload);
    yield put(membersActions.request(action.payload)); //quick hack, should use optimistic ui instead (TODO)
    yield put(managersActions.request(action.payload)); //quick hack, should use optimistic ui instead (TODO)
  } catch (err) {
    console.log(err);
  }
}

function* getOrgs() {
  try {
    const permissions = yield select(getPermissions);
    if (!permissions.includes(PERMISSIONS.VIEW_SYS_ADMIN_UI)) {
      // no permission to fetch all orgs?
      const authOrg = yield select(getOrg); // get user's org from redux auth object
      yield put(orgsActions.success([authOrg]));
      return;
    }
    const res = yield fetchOrgs();
    yield put(orgsActions.success(res));
  } catch (err) {
    yield put(orgsActions.failure(err.message));
  }
}

function* getDivs(action) {
  try {
    const res = yield fetchDivs(action.payload);
    yield put(divsActions.success(res));
  } catch (err) {
    yield put(divsActions.failure(err.message));
  }
}

function* getTeams(action) {
  try {
    const res = yield fetchTeams(action.payload);
    yield put(teamsActions.success(res));
  } catch (err) {
    yield put(teamsActions.failure(err.message));
  }
}

function* getMembers(action) {
  try {
    yield* fetchDataWorkerSaga(
      types.members,
      fetchMembers,
      action.payload,
      {},
      res => {
        res.forEach(item => {
          item.rolesReadable = item?.roles?.reduce((acc, curr) => {
            if (curr === 'global.team_manager') acc.push('Team Supervisor');
            if (curr === 'global.field_tech') acc.push('Field Technician');
            return acc;
          }, []);
          item.nameReadable = `${item?.firstname} ${item?.lastname}`;
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getManagers(action) {
  try {
    yield* fetchDataWorkerSaga(
      types.managers,
      fetchManagers,
      action.payload,
      {},
      res => {
        res.forEach(item => {
          item.rolesReadable = item?.roles?.reduce((acc, curr) => {
            if (curr === 'global.area_manager') acc.push('Area Manager');
            if (curr === 'global.utility_manager') acc.push('Utility Manager');
            return acc;
          }, []);
          item.nameReadable = `${item?.firstname} ${item?.lastname}`;
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getUsers(action) {
  try {
    yield* fetchDataWorkerSaga(
      types.users,
      fetchUsers,
      action.payload,
      {},
      res => {
        res.forEach(item => {
          item.rolesReadable = item?.roles?.reduce((acc, curr) => {
            if (curr === 'global.team_manager') acc.push('Team Supervisor');
            if (curr === 'global.field_tech') acc.push('Field Technician');
            return acc;
          }, []);
          item.nameReadable = `${item?.firstname} ${item?.lastname}`;
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* editUserDetails(action) {
  try {
    const { email, org, ...params } = action.payload;
    yield* fetchDataWorkerSaga(
      types.editUser,
      editUser,
      { email, org },
      params,
      res => {
        res.rolesReadable = res?.roles?.map(r => {
          if (r === 'global.team_manager') return 'Team Supervisor';
          if (r === 'global.field_tech') return 'Field Technician';
          return '';
        });
        res.nameReadable = `${res?.firstname} ${res?.lastname}`;
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* accountSuspension(action) {
  try {
    const { id, ...params } = action.payload;
    yield* fetchDataWorkerSaga(
      types.userAccountSuspension,
      editUserAccountSuspension,
      params,
      {},
      res => {
        res.id = id;
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* setVrs(action) {
  try {
    const { id, ...params } = action.payload;
    yield* fetchDataWorkerSaga(
      types.userVrs,
      editUserVrs,
      params,
      {},
      res => {
        res.id = id;
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* submitSupportForm(action) {
  try {
    yield* fetchDataWorkerSaga(
      types.newSupportForm,
      postSupportForm,
      action.payload,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

function* getUtilityTypes() {
  try {
    yield* fetchDataWorkerSaga(types.utilityTypes, fetchUtilityTypes);
  } catch (err) {
    console.log(err);
  }
}

function* setUtilityType(action) {
  try {
    const { reportId, ...rest } = action.payload;
    yield* fetchDataWorkerSaga(
      types.newUtilityTypeAllocation,
      putUtilityType,
      reportId,
      rest,
      () => rest
    );
  } catch (err) {
    console.log(err);
  }
}

function* getUnprocessedUploads() {
  try {
    yield* fetchDataWorkerSaga(
      types.unprocessedUploads,
      fetchUnprocessedUploads
    );
  } catch (err) {}
}

function* reRunUpload(action) {
  try {
    yield call(reprocessUpload, action.payload.id);
    yield put(reprocessUploadActions.success({ id: action.payload.id }));
  } catch (err) {
    yield put(reprocessUploadActions.failure({ id: action.payload.id }));
  }
}

function* deleteUpload(action) {
  try {
    const { id } = action.payload;
    yield call(annihilateUpload, id);
    yield put(deleteUploadActions.success({ id }));
  } catch (err) {
    yield put(deleteUploadActions.failure({ id: action.payload.id }));
  }
}

function* getUploads(action) {
  try {
    yield* fetchDataWorkerSaga(types.uploads, fetchUploads, action.payload);
  } catch (err) {
    console.log(err);
  }
}

function* refresh() {
  try {
    // This doesnt return until the refresh is over - could take a while
    // No point sending anymore requests till you get a response of time out
    const result = yield call(triggerRefresh);
    yield put({ type: types.refreshUnprocessed.SUCCESS, payload: result });
    yield put({ type: types.unprocessedUploads.REQUEST });
  } catch (err) {
    yield put({ type: types.refreshUnprocessed.FAILURE, payload: err });
  }
}

function* getSingleUpload(action) {
  try {
    const { id, ...rest } = action.payload;
    yield* fetchDataWorkerSaga(
      types.singleUpload,
      fetchSingleUpload,
      id,
      rest,
      res => {
        if (Array.isArray(res)) {
          return res.shift();
        } else {
          return res;
        }
      }
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* sagas() {
  yield all([
    yield debounce(50, types.newOrg.REQUEST, createOrg),
    yield debounce(50, types.newDiv.REQUEST, createDiv),
    yield debounce(50, types.newTeam.REQUEST, createTeam),
    yield debounce(50, types.newUser.REQUEST, createUser),
    yield debounce(50, types.newRole.REQUEST, createRole),
    yield debounce(50, types.deleteRole.REQUEST, removeRole),
    yield debounce(50, types.orgs.REQUEST, getOrgs),
    yield debounce(50, types.divs.REQUEST, getDivs),
    yield debounce(50, types.teams.REQUEST, getTeams),
    yield debounce(50, types.members.REQUEST, getMembers),
    yield debounce(50, types.managers.REQUEST, getManagers),
    yield debounce(50, types.users.REQUEST, getUsers),
    yield debounce(50, types.editUser.REQUEST, editUserDetails),
    yield debounce(50, types.userAccountSuspension.REQUEST, accountSuspension),
    yield debounce(50, types.userVrs.REQUEST, setVrs),
    yield debounce(50, types.newSupportForm.REQUEST, submitSupportForm),
    yield debounce(50, types.utilityTypes.REQUEST, getUtilityTypes),
    yield debounce(50, types.newUtilityTypeAllocation.REQUEST, setUtilityType),
    yield debounce(50, types.unprocessedUploads.REQUEST, getUnprocessedUploads),
    yield debounce(50, types.reprocessUpload.REQUEST, reRunUpload),
    yield debounce(50, types.deleteUpload.REQUEST, deleteUpload),
    yield debounce(750, types.uploads.REQUEST, getUploads),
    yield debounce(1000, types.refreshUnprocessed.REQUEST, refresh),
    yield debounce(50, types.singleUpload.REQUEST, getSingleUpload)
  ]);
}
