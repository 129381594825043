import { put, call } from 'redux-saga/effects';
import logger from 'services/logger';

const filterParams = params =>
  params && typeof params === 'object'
    ? Object.keys(params).reduce((result, key) => {
        if (!params[key] && params[key] !== false) {
          return result;
        }
        return {
          ...result,
          [key]: params[key]
        };
      }, {})
    : params;

/**
 * USAGE EXAMPLE:
 *
 * function* test() {
 *    const otherStuff = yeild select(selectorsdfsdf)
 *    yield* fetchDataWorkerSaga(action.type, api.doasthin, otherstuff)
 * }
 *
 * debounce(type.REQUEST, test)
 */
export function* fetchDataWorkerSaga(
  type,
  func,
  args,
  params,
  transformer = res => res
) {
  try {
    const res = yield call(func, filterParams(args), params);
    yield put({ type: type.SUCCESS, payload: transformer(res) });
    return res;
  } catch (err) {
    yield put({ type: type.FAILURE, payload: err.message });
    logger.error(err.message);
  }
}

export const postDataWorkerSaga = fetchDataWorkerSaga;
