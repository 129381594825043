import { generateFetchDataTypes, generateFetchDataActions } from 'ducks/utils';

export const types = {
  REPORTS: 'UTTO/TECHNICIAN/REPORTS',
  POINTS: 'UTTO/TECHNICIAN/POINTS',
  ASSETS: 'UTTO/TECHNICIAN/ASSETS',
  SESSIONS: 'UTTO/TECHNICIAN/SESSIONS',
  SELECTED_SESSION: 'UTTO/TECHNICIAN/SELECTED_SESSION',
  BREADCRUMBS: 'UTTO/TECHNICIAN/BREADCRUMBS',
  scoresHistory: generateFetchDataTypes('UTTO/TECHNICIAN/SCORES_HISTORY'),
  technicianActivityHistory: generateFetchDataTypes(
    'UTTO/TECHNICIAN/ACTIVITY_HISTORY'
  ),
  technicianActivityHistogram: generateFetchDataTypes(
    'UTTO/TECHNICIAN/ACTIVITY_HISTOGRAM'
  ),
  teamAverageScoresHistory: generateFetchDataTypes(
    'UTTO/TECHNICIAN/TEAM_AVERAGE_SCORES_HISTORY'
  ),
  siteReports: generateFetchDataTypes('UTTO/TECHNICIAN/SITE_REPORTS'),
  siteReport: generateFetchDataTypes('UTTO/TECHNICIAN/SITE_REPORT'),
  emPoints: generateFetchDataTypes('UTTO/TECHNICIAN/EM_POINTS')
};

export const reports = () => ({
  type: types.REPORTS
});

export const points = () => ({
  type: types.POINTS
});

export const assets = () => ({
  type: types.ASSETS
});

export const breadcrumbs = () => ({
  type: types.BREADCRUMBS
});

export const sessions = () => ({
  type: types.SESSIONS
});

export const scoresHistoryActions = generateFetchDataActions(
  types.scoresHistory
);

export const technicianActivityHistoryActions = generateFetchDataActions(
  types.technicianActivityHistory
);

export const teamAverageScoresHistoryActions = generateFetchDataActions(
  types.teamAverageScoresHistory
);

export const technicianActivityHistogramActions = generateFetchDataActions(
  types.technicianActivityHistogram
);

export const siteReportsActions = generateFetchDataActions(types.siteReports);
export const siteReportActions = generateFetchDataActions(types.siteReport);
export const emPointsActions = generateFetchDataActions(types.emPoints);
