export const convertMillimetersToFeet = value => value / 304.8;
export const convertMillimetersToInches = value => value / 25.4;

export const convertDegrees2Meters = (lon, lat) => {
  const rMajor = 6378137; //Equatorial Radius, WGS84
  const shift = Math.PI * rMajor;
  const x = (lon * shift) / 180;
  let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * shift) / 180;
  return [x, y];
};

export const convertMetresToInches = metres => metres * 39.3701;

export const convertMetresToFeet = metres => metres * 3.28084;

export const convertFeetToMetres = feet => feet * 0.3048;

export const convertMetresToMiles = metres => metres * 0.00062137121212121;

export const convertMetresPerSecondToMilesPerHour = mps => mps * 2.23694;

export const calculateAverageDistanceBetweenPoints = histogram => {
  if (!histogram) return '';
  let totalDistance = 0;
  let totalPoints = 0;

  Object.keys(histogram).forEach(key => {
    totalDistance += key * histogram[key];
    totalPoints += histogram[key];
  });

  return totalDistance / totalPoints;
};

// NOTE: this is disabled, but left here in case it is needed again in future
export const applyScoreToCurve = x => x;
/*
// Assumes 0 < x < 100
export const applyScoreToCurve = x => {
  if (x === 0) {
    return 0;
  }
  if (x === null || isNaN(x)) {
    return x;
  }
  if (x >= 100) return 100;

  return Math.round(
    105.0139 + (-0.05202412 - 105.0139) / (1 + (x / 39.81633) ** 3.244596)
  );
};
*/
