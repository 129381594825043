import React, { Suspense, lazy, useEffect } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { getIsLoggedIn } from 'ducks/auth/selectors';
import { useReduxSelector, usePermissions, useRouter } from 'hooks';
import { PERMISSIONS } from 'config';
import history from 'services/history';
import { Forbidden, NotFound } from 'pages/errorPages';
import SlimTemplate from 'pages/slimTemplate';
import Login from 'pages/login';
import Support from 'pages/support';
import Terms from 'pages/terms';
import PrivacyPolicy from 'pages/privacy';
import PrivacyPolicyGB from 'pages/privacyGB';
import useStyles from './styles';

const Admin = lazy(() => import('pages/admin'));
const Account = lazy(() => import('pages/account'));
const Team = lazy(() => import('pages/team'));
const Area = lazy(() => import('pages/area'));
const Technician = lazy(() => import('pages/technician'));

const DefaultRedirect = () => {
  const isLoggedIn = useReduxSelector(getIsLoggedIn);
  const [
    canViewSysAdmin,
    canViewAdmin,
    canViewTeam,
    canViewTechnician,
    canViewDemoUI,
    canViewDivision
  ] = usePermissions([
    PERMISSIONS.VIEW_SYS_ADMIN_UI,
    PERMISSIONS.VIEW_ADMIN_UI,
    PERMISSIONS.VIEW_TEAM_UI,
    PERMISSIONS.VIEW_FIELD_TECH_UI,
    PERMISSIONS.VIEW_SINGLE_USER_DEMO_UI,
    PERMISSIONS.VIEW_DIVISION_UI
  ]);

  if (!isLoggedIn) return <Redirect to="/login" />;
  if (canViewSysAdmin || canViewAdmin) return <Redirect to="/admin" />;
  if (canViewDivision) return <Redirect to="/area" />;
  if (canViewTeam || canViewDemoUI) return <Redirect to="/team" />;
  if (canViewTechnician) return <Redirect to="/technician" />;
  return <Route component={Forbidden} />;
};

const PrivateRoute = ({ permissions = [], component, ...rest }) => {
  const hasPermissions = usePermissions(permissions);
  const canViewPage = hasPermissions.some(p => p === true);
  const isLoggedIn = useReduxSelector(getIsLoggedIn);

  if (!isLoggedIn) return <Redirect to="/login" />;
  return <Route component={canViewPage ? component : Forbidden} {...rest} />;
};

const LazyLoading = () => {
  const classes = useStyles();

  return (
    <SlimTemplate>
      <div className={classes.dialogContainer}>
        <CircularProgress size={160} thickness={2.4} />
      </div>
    </SlimTemplate>
  );
};

const ScrollToTopController = () => {
  const { location } = useRouter();
  useEffect(() => {
    // try using the new browser API, if it fails, use the old one
    try {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } catch (e) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return null;
};

const CustomRouter = () => (
  <Router history={history}>
    <ScrollToTopController />
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route exact path="/" component={DefaultRedirect} />
        <Route path="/login" component={Login} />
        <Route path="/account" component={Account} />
        <Route path="/support" component={Support} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/privacypolicy-us" component={PrivacyPolicy} />
        <Route path="/privacypolicy-gb" component={PrivacyPolicyGB} />
        <PrivateRoute
          path="/team"
          component={Team}
          permissions={[
            PERMISSIONS.VIEW_TEAM_UI,
            PERMISSIONS.VIEW_SINGLE_USER_DEMO_UI
          ]}
        />
        <PrivateRoute
          path="/area"
          component={Area}
          permissions={[PERMISSIONS.VIEW_DIVISION_UI]}
        />
        <PrivateRoute
          path="/technician"
          component={Technician}
          permissions={[PERMISSIONS.VIEW_FIELD_TECH_UI]}
        />
        <PrivateRoute
          path="/admin"
          component={Admin}
          permissions={[
            PERMISSIONS.VIEW_SYS_ADMIN_UI,
            PERMISSIONS.VIEW_ADMIN_UI
          ]}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </Router>
);

export default CustomRouter;
