import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import history from 'services/history';
import { withTracker } from 'utils';
import SlimTemplate from 'pages/slimTemplate';
import useStyles from './styles';

const Template = ({ title, description, route }) => {
  const classes = useStyles();

  return (
    <SlimTemplate title={title}>
      <Typography gutterBottom variant="h2">
        {title}
      </Typography>
      <Typography gutterBottom variant="h5">
        {description}
      </Typography>
      <Button
        size="large"
        color="primary"
        variant="contained"
        className={classes.returnButton}
        onClick={() => history.push(route)}
      >
        Return to homepage
      </Button>
    </SlimTemplate>
  );
};

export const Forbidden = withTracker(() => (
  <Template
    title="Oops!"
    description={`Access to this page is not granted. Please check with your administrator to ensure your team assignment is set up correctly.`}
    route="/login"
    />
));

export const NotFound = withTracker(() => (
  <Template
    title="Not Found"
    description="The page you requested has not been found. If this issue persists, please contact your administrator."
    route="/"
    />
));
