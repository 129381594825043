import React from 'react';
import { Button, Typography } from '@material-ui/core';
import history from 'services/history';
import useStyles from './styles';
import classnames from 'classnames';

const redirect = href => event => {
  event.preventDefault();
  history.push(href);
};

const links = [
  {
    label: 'Support',
    href: '/support',
    onClick: event => {
      event.preventDefault();
      const url = window.location.href;
      history.push('/support', { referred_from: url });
    }
  },
  {
    label: 'FAQs',
    href: 'https://utto.freshdesk.com/support/solutions',
    target: '_blank'
  }
];

const Footer = ({ classname }) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <div className={classnames(classname, classes.foot)}>
      <div className={classes.links}>
        <Typography variant="button" color="inherit">
          © {currentYear} UTTO Technologies
        </Typography>
        <Button color="inherit" href="/terms" onClick={redirect('/terms')}>
          Terms
        </Button>
        <Button
          color="inherit"
          href="/privacypolicy"
          onClick={redirect('/privacypolicy')}
        >
          Privacy
        </Button>
      </div>
      <div className={classes.links}>
        {links.map(({ href, label, onClick, target }) => (
          <Button key={label} color="inherit" href={href} target={target} onClick={onClick}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Footer;
