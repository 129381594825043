import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName
} from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import store from 'store';
import Router from 'pages/router';
import theme from './theme';
import UttoSnackbar from 'components/snackbar';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c'
});

const App = () => (
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UttoSnackbar />
        <Helmet>
          <title>UTTO</title>
        </Helmet>
        <Router />
      </ThemeProvider>
    </StylesProvider>
  </Provider>
);

export default App;
