import { useMemo, useCallback, useState } from 'react';
import querystring from 'query-string';
import { useRouter } from 'hooks';

const QUERY_STRING_OPTIONS = {
  arrayFormat: 'bracket'
};

/**
 * A React hook to manage state using the query string
 * Currently only compatible with `react-router` v4.4.0-beta
 * (as it uses a custom hook based on __RouterContext)
 */
const useQueryString = (key, initialValue) => {
  // NOTE: use location for memoization, because history is mutable
  const [isInitialValue, setIsInitialValue] = useState(true);
  const { history, location } = useRouter();
  const value = useMemo(() => {
    const qsValue = querystring.parse(location.search, QUERY_STRING_OPTIONS)[
      key
    ];
    // only return initialValue if there is no value in the querystring
    // and no value has been set by the callback either
    if (!qsValue && isInitialValue) return initialValue;
    return qsValue;
  }, [location.search, key, isInitialValue, initialValue]);

  const setValue = useCallback(
    newValue => {
      // NOTE: must get the latest querystring via `history.location`
      // to prevent race conditions with `location` as history is a singleton
      const query = querystring.parse(
        history.location.search,
        QUERY_STRING_OPTIONS
      );
      const newQuery = querystring.stringify(
        { ...query, [key]: newValue },
        QUERY_STRING_OPTIONS
      );
      history.replace({
        pathname: location.pathname,
        search: newQuery
      });
      setIsInitialValue(false);
    },
    [key, history, location]
  );

  return [value, setValue];
};

export default useQueryString;
