import moment from 'moment';

// NOTE: don't change this, use the REACT_APP_PROXY env var in development
export const API_BASE_URL = '/v1/';

export const REACT_APP_BUILD = window.__env__?.REACT_APP_BUILD || 'dev';

// Google maps url
export const MAP_URL =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' +
  window.__env__?.GOOGLE_MAPS_API_KEY;

export const MAP_TOKEN =
  'pk.eyJ1Ijoia2FpLXV0dG8iLCJhIjoiY2p6NDl2eXh6MGI1ZjNscGFsdGNnMDIwZSJ9.rpR1_kxUKa_gKZvtdzi5jg';

export const DEFAULT_MAP_BOUNDS = {
  lat: 52.324866,
  lon: -0.075902
};

export const ASSET_BOUNDS_DISTANCE = 2000; // 2km
export const ASSET_TIME_FRAME = 30; // 30 minutes

export const ENABLED_METRICS = [
  'centering',
  'lift',
  'depth',
  'gain',
  'aim',
  'null',
  'peak',
  'swing',
  'vertical',
  'compass',
  'current',
  'frequency',
];

export const DATE_RANGE_OPTIONS = [
  {
    label: 'Today',
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    per: 'day'
  },
  {
    label: 'Yesterday',
    startDate: moment().subtract(1, 'days').startOf('day'),
    endDate: moment().subtract(1, 'days').endOf('day'),
    per: 'day'
  },
  {
    label: 'Last 7 Days',
    startDate: moment().subtract(7, 'days').startOf('day'),
    endDate: moment().endOf('day'),
    per: 'day',
  },
  {
    label: 'Last 30 Days',
    startDate: moment().subtract(30, 'days').startOf('day'),
    endDate: moment().endOf('day'),
    per: 'day',
  },
  {
    label: 'This Week',
    startDate: moment().startOf('week').startOf('day'),
    endDate: moment().endOf('day'),
    per: 'day',
  },
  {
    label: 'Last Week',
    startDate: moment().subtract(7, 'days').startOf('week').startOf('day'),
    endDate: moment().subtract(7, 'days').endOf('week').endOf('day'),
    per: 'day'
  },
  {
    label: 'This Month',
    startDate: moment().startOf('month').startOf('day'),
    endDate: moment().endOf('month').endOf('day'),
    per: 'week',
  },
  {
    label: 'Last Month',
    startDate: moment().subtract(1, 'month').startOf('month').startOf('day'),
    endDate: moment().subtract(1, 'month').endOf('month').endOf('day'),
    per: 'week'
  },
  {
    label: 'Last 3 Months',
    startDate: moment().subtract(3, 'month').startOf('month').startOf('day'),
    endDate: moment().endOf('day'),
    per: 'week'
  }
];

//DATE_RANGE_OPTIONS.forEach(x => {
  //console.log(`${x.label} ${x.startDate} ${x.endDate}`);
//});

export const DEFAULT_DATE_RANGE = DATE_RANGE_OPTIONS[3];

export const ROLES = {
  UTILITY_MANAGER: 'global.utility_manager',
  AREA_MANAGER: 'global.area_manager',
  FIELD_TECH: 'global.field_tech'
};

export const PERMISSIONS = {
  VIEW_FIELD_TECH_UI: 'global.view_field_tech_ui',
  VIEW_TEAM_UI: 'global.view_team_ui',
  VIEW_ADMIN_UI: 'global.view_admin_ui',
  VIEW_SINGLE_USER_DEMO_UI: 'global.view_single_user_demo_ui',
  VIEW_SYS_ADMIN_UI: 'global.view_system_admin_ui',
  VIEW_DIVISION_UI: 'global.view_division_ui',
  VIEW_UTILITY_UI: 'global.view_utility_ui'
};

export const PERMISSIONS_WEIGHT = {
  [PERMISSIONS.VIEW_TEAM_UI]: 1,
  [PERMISSIONS.VIEW_DIVISION_UI]: 2,
  [PERMISSIONS.VIEW_UTILITY_UI]: 3,
  [PERMISSIONS.VIEW_ADMIN_UI]: 90,
  [PERMISSIONS.VIEW_SYS_ADMIN_UI]: 99
};

export const VIEW_TYPES = [
  {
    label: 'Administration',
    href: '/admin',
    permissions: [PERMISSIONS.VIEW_SYS_ADMIN_UI, PERMISSIONS.VIEW_ADMIN_UI]
  },
  {
    label: 'Area Manager',
    href: '/area',
    allowMultiple: true,
    permissions: [PERMISSIONS.VIEW_DIVISION_UI]
  },
  {
    label: 'Utility Manager',
    href: '/area',
    allowMultiple: true,
    permissions: [PERMISSIONS.VIEW_UTILITY_UI]
  },
  {
    label: 'Team Supervisor',
    href: '/team',
    allowMultiple: true,
    permissions: [
      PERMISSIONS.VIEW_TEAM_UI,
      PERMISSIONS.VIEW_SINGLE_USER_DEMO_UI
    ]
  },
  {
    label: 'My Locates',
    href: '/technician',
    allowMultiple: true,
    permissions: [PERMISSIONS.VIEW_FIELD_TECH_UI]
  }
];

export const SCORE_CLASSIFIER = score => {
  if (score === null) return 'na';
  if (score <= 40) return 'bad';
  if (score <= 70) return 'okay';
  return 'good';
};

export const EXPECTED_SCORE = 0.55;

export const SESSION_FILTERS = {
  PROBLEM: 1,
  OK: 2,
  GOOD: 3
};

export const SESSION_FILTERS_RANGE = {
  MIN: 40,
  MAX: 70
};
