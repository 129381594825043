import { makeStyles } from '@material-ui/styles';

const styles = theme => ({
  success: {
    backgroundColor: theme.utilityColors.Green
  },
  error: {
    backgroundColor: theme.utilityColors.Red
  },
  info: {
    backgroundColor: theme.utilityColors.Blue
  },
  warning: {
    backgroundColor: theme.utilityColors.Orange
  },
  icon: {
    fontSize: 20
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

export default makeStyles(styles, { name: 'UTTO-Components-Snackbar' });
