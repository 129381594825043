import useQueryString from './useQueryString';
import { useMemo } from 'react';

const useSortParams = () => {
  const [sortByData] = useQueryString('sortedData');

  const sort = useMemo(() => {
    if (sortByData) {
      return {
        orderBy: sortByData[0],
        orderDirection: sortByData[1]
      };
    }
    return {};
  }, [sortByData]);

  return [sort];
};

export default useSortParams;
