import {
  createReducer,
  combineReducersWithTeamChangeReset,
  generateFetchDataReducers,
  generatePostDataReducers,
  FETCH_DATA_INITIAL_STATE,
  POST_DATA_INITIAL_STATE
} from 'ducks/utils';
import { types } from './actions';
import { types as adminTypes } from 'ducks/admin/actions';

const removeBy = (data, key, value) => data?.filter(d => d[key] !== value);

const mapAlertForRemove = (alerts, payload, id, toBe = true) => {
  return alerts?.map(alert =>
    alert.id === id
      ? {
          ...alert,
          resolution: { ...alert.resolution, ...payload },
          toBeRemoved: toBe,
          isInProgress: false
        }
      : alert
  );
};

const mapAlertForInProgress = (alerts, id, { id: _, ...resolution } = {}) => {
  return alerts?.map(alert =>
    alert.id === id
      ? {
          ...alert,
          resolution,
          isInProgress: !!resolution.issueType && !resolution.resolution
        }
      : alert
  );
};

const updateAlertHeaders = (headers, count) =>
  headers && {
    ...headers,
    'X-Total-Count': count
  };

const locators = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.locators)
);
const scoresHistory = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.scoresHistory)
);
const siteReports = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.siteReports)
);
const activeUsers = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.activeUsers)
);
const siteReport = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.siteReport),
  [adminTypes.newUtilityTypeAllocation.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: { ...state.data, utility_type: action?.payload?.utilityType }
    };
  }
});
const siteReportAlerts = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.siteReportAlerts),
  [types.siteReportAlerts.SUCCESS]: (state, action) => {
    return {
      ...state,
      pending: false,
      data: action.payload
    };
  },
  [types.alertResolve.REQUEST]: (state, action) => {
    const alerts = action.payload?.resolution
      ? mapAlertForRemove(state.data, action.payload, action.payload?.id)
      : mapAlertForInProgress(state.data, action.payload?.id, action.payload);

    return {
      ...state,
      data: alerts
    };
  },
  [types.alertResolve.SUCCESS]: (state, action) => ({
    ...state,
    data: mapAlertForRemove(
      state.data,
      action.payload,
      action.payload?.id,
      action.payload?.resolution && action.payload?.issueType ? true : false
    )
  }),
  [types.alertResolve.FAILURE]: (state, action) => {
    const alerts = mapAlertForInProgress(
      mapAlertForRemove(
        state.data?.body,
        action.payload,
        action.payload?.id,
        false
      ),
      action.payload?.id
    );

    return {
      ...state,
      data: alerts
    };
  }
});
const activityHistory = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.activityHistory)
);
const teamActivityHistograms = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.teamActivityHistograms)
);
const alertsScores = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.scoresAlerts)
);
const alerts = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.alerts),
  [types.alertResolve.REQUEST]: (state, action) => {
    const alerts = action.payload?.resolution
      ? mapAlertForRemove(state.data?.body, action.payload, action.payload?.id)
      : mapAlertForInProgress(
          state.data?.body,
          action.payload?.id,
          action.payload
        );

    return {
      ...state,
      data: {
        ...state.data,
        body: alerts,
        headers:
          action.payload?.resolution && alerts
            ? updateAlertHeaders(state.data?.headers, alerts.length - 1)
            : state.data?.headers
      }
    };
  },
  [types.alertResolve.SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      body: action.payload?.resolved
        ? removeBy(state.data?.body, 'id', action.payload?.id)
        : mapAlertForRemove(
            state.data?.body,
            action.payload,
            action.payload?.id,
            false
          )
    }
  }),
  [types.alertResolve.FAILURE]: (state, action) => {
    const alerts = mapAlertForInProgress(
      mapAlertForRemove(
        state.data?.body,
        action.payload,
        action.payload?.id,
        false
      ),
      action.payload?.id
    );

    return {
      ...state,
      data: {
        ...state.data,
        body: alerts,
        headers: updateAlertHeaders(state.data?.headers, alerts.length)
      }
    };
  }
});

const alertsSettings = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.alertsSettings)
);

const saveAlertsSettings = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducers(types.saveAlertsSettings)
);

export default combineReducersWithTeamChangeReset({
  locators,
  scoresHistory,
  activityHistory,
  teamActivityHistograms,
  siteReports,
  activeUsers,
  siteReport,
  siteReportAlerts,
  alerts,
  alertsSettings,
  saveAlertsSettings,
  alertsScores
});
