import { types } from './actions';
import {
  createReducer,
  generateFetchDataReducers,
  FETCH_DATA_INITIAL_STATE
} from 'ducks/utils';
import { combineReducers } from 'redux';

const editUserList = (state, action) => ({
  ...state,
  data: state?.data?.map(item =>
    item.id === action.payload.id
      ? {
          ...action.payload,
          email: item?.email,
          roles: item?.roles,
          rolesReadable: item?.rolesReadable,
          enabled: item?.enabled
        }
      : item
  )
});

const toggleSuspension = (state, action) => ({
  ...state,
  data: state?.data?.map(item => {
    if (item.id === action.payload.id) {
      console.log('SUSPENSION REDUCER', item);
      return {  ...item,
                enabled: !item?.enabled,
                vrs: { enabled: false } 
      };
    } else {
      return item;
    }
  })
});

const toggleVrs = (state, action) => ({
  ...state,
  data: state?.data?.map(item => {
    if (item.id === action.payload.id) {
      console.log('VRS REDUCER', item);
      return { ...item, vrs: { enabled: item?.vrs?.enabled ? false : true } };
    } else {
      return item;
    }
  })
});


// retrieving lists of entities from server
const orgs = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.orgs),
  [types.newOrg.SUCCESS]: (state, action) => ({
    ...state,
    data: [...state.data, action.payload.name]
  })
});
const divs = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.divs),
  [types.newDiv.SUCCESS]: (state, action) => ({
    ...state,
    data: [...state.data, action.payload.name]
  })
});
const teams = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.teams),
  [types.newTeam.SUCCESS]: (state, action) => ({
    ...state,
    data: [...state.data, action.payload.name]
  })
});
const members = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.members),
  [types.editUser.SUCCESS]: editUserList,
  [types.userAccountSuspension.SUCCESS]: toggleSuspension,
  [types.userVrs.SUCCESS]: toggleVrs
});
const managers = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.managers),
  [types.editUser.SUCCESS]: editUserList,
  [types.userAccountSuspension.SUCCESS]: toggleSuspension,
  [types.userVrs.SUCCESS]: toggleVrs
});
const users = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.users),
  [types.newUser.SUCCESS]: (state, action) => ({
    ...state,
    data: [...state.data, action.payload]
  }),
  [types.editUser.SUCCESS]: editUserList,
  [types.userAccountSuspension.SUCCESS]: toggleSuspension,
  [types.userVrs.SUCCESS]: toggleVrs
});
// posting new entities
const newOrg = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newOrg)
);
const newDiv = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newDiv)
);
const newTeam = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newTeam)
);
const newUser = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newUser)
);
const newSupportForm = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newSupportForm)
);
const utilityTypes = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.utilityTypes)
);
const newUtilityTypeAllocation = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.newUtilityTypeAllocation)
);
const unprocessedUploads = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.unprocessedUploads),
  [types.reprocessUpload.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: state?.data?.map(item =>
        item.id === action.payload.id
          ? { ...item, status: 'reprocessing...' }
          : item
      )
    };
  },
  [types.reprocessUpload.FAILURE]: (state, action) => {
    return {
      ...state,
      data: state?.data?.map(item =>
        item.id === action.payload.id
          ? { ...item, status: 'reprocess failed' }
          : item
      )
    };
  },
  [types.deleteUpload.SUCCESS]: (state, action) => ({
    ...state,
    data: state?.data?.map(item =>
      item.id === action.payload.id ? { ...item, status: 'deleted' } : item
    )
  }),
  [types.deleteUpload.FAILURE]: (state, action) => ({
    ...state,
    data: state?.data?.map(item =>
      item.id === action.payload.id
        ? { ...item, status: 'delete failed' }
        : item
    )
  })
});
const uploads = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.uploads),
  [types.reprocessUpload.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: state?.data?.map(item =>
        item.id === action.payload.id
          ? { ...item, status: 'reprocessing...' }
          : item
      )
    };
  },
  [types.reprocessUpload.FAILURE]: (state, action) => {
    return {
      ...state,
      data: state?.data?.map(item =>
        item.id === action.payload.id
          ? { ...item, status: 'reprocess failed' }
          : item
      )
    };
  },
  [types.deleteUpload.SUCCESS]: (state, action) => ({
    ...state,
    data: state?.data?.map(item =>
      item.id === action.payload.id ? { ...item, status: 'deleted' } : item
    )
  }),
  [types.deleteUpload.FAILURE]: (state, action) => ({
    ...state,
    data: state?.data?.map(item =>
      item.id === action.payload.id
        ? { ...item, status: 'delete failed' }
        : item
    )
  })
});
const refreshUnprocessedUploads = createReducer(
  { pending: false, error: null, debugMsg: null },
  {
    [types.refreshUnprocessed.REQUEST]: (state, action) => ({
      ...state,
      pending: true
    }),
    [types.refreshUnprocessed.FAILURE]: (state, action) => ({
      ...state,
      pending: false,
      debugMsg: action?.payload
    }),
    [types.refreshUnprocessed.SUCCESS]: (state, action) => ({
      ...state,
      pending: false,
      debugMsg: action?.payload
    })
  }
);
const singleUpload = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.singleUpload)
);

export default combineReducers({
  orgs,
  divs,
  teams,
  members,
  managers,
  users,
  newOrg,
  newDiv,
  newTeam,
  newUser,
  newSupportForm,
  utilityTypes,
  newUtilityTypeAllocation,
  unprocessedUploads,
  uploads,
  refreshUnprocessedUploads,
  singleUpload
});
