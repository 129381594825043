import { generateFetchDataTypes, generateFetchDataActions } from 'ducks/utils';

export const types = {
  teams: generateFetchDataTypes('UTTO/AREA/TEAMS'),
  studies: generateFetchDataTypes('UTTO/AREA/STUDIES'),
  damages: generateFetchDataTypes('UTTO/AREA/DAMAGES'),

};

export const teamActions = generateFetchDataActions(types.teams);
export const studiesActions = generateFetchDataActions(types.studies);
export const damagesActions = generateFetchDataActions(types.damages);
