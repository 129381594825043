import { useMemo, useCallback } from 'react';
import moment from 'moment';
import logger from 'services/logger';
import { useQueryString } from 'hooks';
import { DEFAULT_DATE_RANGE } from 'config';

const deltaDays = (start, end) => {
  return Math.ceil((end - start) / 1000 / 60 / 60 / 24);
};

const useDateRangeParams = () => {
  // use a query string parameter for start date
  const [startDate, setStartDate] = useQueryString(
    'startDate',
    DEFAULT_DATE_RANGE.startDate.utc().toISOString(),
  );
  // use a query string parameter for end date
  const [endDate, setEndDate] = useQueryString(
    'endDate',
    DEFAULT_DATE_RANGE.endDate.utc().toISOString(),
  );

  const [per, setPer] = useQueryString('per', DEFAULT_DATE_RANGE.per);

  // only create new moment instances when the query string paramter changes
  const start = useMemo(() => startDate && moment.utc(startDate), [startDate]);
  const end = useMemo(() => endDate && moment.utc(endDate), [endDate]);

  // callback function to set both dates, handling formatting
  const setDates = useCallback(
    ({ startDate, endDate, per }) => {
      const startUTC = startDate?.utc().toISOString();
      const endUTC = endDate?.utc().toISOString();

      var newPer = per;
      if (deltaDays(startDate, endDate) < 28) {
        newPer = 'day'
      }

      logger.log('Changing date filter', {
        start: startUTC,
        end: endUTC,
        per: newPer
      });
      setStartDate(startUTC);
      setEndDate(endUTC);
      setPer(newPer);
    },
    [setStartDate, setEndDate, setPer]
  );

  return [start, end, per, setDates];
};

export default useDateRangeParams;
