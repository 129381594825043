import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SlimTemplate from 'pages/slimTemplate';
import useStyles from './styles';

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <SlimTemplate showMenu title="Privacy Policy">
      <Paper className={classes.documentWrapper}>
        <Typography variant="h4" className={classes.formTitle}>
          Privacy Policy
        </Typography>
        <Typography gutterBottom variant="h6">
          Last updated: May 01, 2024
        </Typography>
        <Typography paragraph>
          IPEG Corporation and its affiliates UTTO Inc. and UTTO Technologies respect
          the privacy of our users. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you use or visit UTTO devices,
          mobile applications or websites. Please read this Privacy Policy carefully.
          IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
          ACCESS UTTO DEVICES, MOBILE APPLICATIONS or WEBSITES.
        </Typography>
        <Typography paragraph>
          We reserve the right to make changes to this Privacy Policy at any time and for
          any reason. We will alert you about any changes by updating the “Last updated”
          date of this Privacy Policy. You are encouraged to periodically review this
          Privacy Policy to stay informed of updates. You will be deemed to have been made
          aware of, will be subject to, and will be deemed to have accepted the changes
          in any revised Privacy Policy by your continued use of UTTO devices, mobile
          applications or websites after the date such revised Privacy Policy is posted.
        </Typography>
        <Typography paragraph>
          This Privacy Policy does not apply to the third-party online or mobile
          stores from which you install the Website or make payments. We are not
          responsible for any of the data collected by any such third party.
        </Typography>
        <Typography gutterBottom variant="h5">
          COLLECTION OF YOUR INFORMATION
        </Typography>
        <Typography paragraph>
          The use of UTTO products may require us to collect information about you in a
          variety of ways. The information we may collect via UTTO devices, mobile
          applications or websites includes:
        </Typography>
        <Typography gutterBottom variant="h6">
          Personal Data
        </Typography>
        <Typography paragraph>
          Personal and identifiable information including your name, company
          name, address, telephone number, email address, location, and billing
          information.
        </Typography>
        <Typography paragraph>
          IPEG and UTTO devices, mobile applications or websites may require registration
          including personal information that will grant access to services, authorization
          codes, license keys, or other features. This information will be used to
          authenticate users, provide support and deliver custom services.
        </Typography>
        <Typography gutterBottom variant="h6">
          Derivative Data
        </Typography>
        <Typography paragraph>
          Information our mobile applications and servers automatically collect when you use
          UTTO products, such as your native actions that are integral to UTTO devices, mobile
          applications or websites, including navigation habits, actions taken, menus used and
          keys pressed.
        </Typography>
        <Typography gutterBottom variant="h6">
          Financial Data
        </Typography>
        <Typography paragraph>
          No financial information is stored on UTTO devices, mobile applications, websites or
          servers other than that required by a 3rd party payment service (check their terms and
          conditions of service if required).
        </Typography>
        <Typography gutterBottom variant="h6">
          Geo-Location Information
        </Typography>
        <Typography paragraph>
          Location information for a UTTO product or a mobile device running UTTO applications
          including information related to above ground or buried facilities. UTTO devices, mobile
          applications or websites may request access or permission to track location-based information
          from a UTTO device or mobile application, either continuously or while you are using UTTO devices,
          applications, or websites, to provide location-based services. If you wish to change our access
          or permissions, you may do so in your device’s settings.
        </Typography>
        <Typography gutterBottom variant="h6">
          Mobile Device Access
        </Typography>
        <Typography paragraph>
          We may request access or permission to certain features from your mobile device, including your
          mobile device’s Bluetooth, camera, Wi-Fi, microphone, sensors, SMS messages, and other features.
          If you wish to change our access or permissions, you may do so in your device’s settings.
        </Typography>
        <Typography gutterBottom variant="h6">
          Mobile Device Data
        </Typography>
        <Typography paragraph>
          Device information such as your mobile device ID number, model, and manufacturer, version of your
          operating system, phone number, country, location, and any other data you choose to provide.
        </Typography>
        <Typography gutterBottom variant="h6">
          Push Notifications
        </Typography>
        <Typography paragraph>
          We may request to send you push notifications regarding your account or UTTO devices, mobile
          applications or websites. If you wish to opt-out from receiving these types of communications,
          you may turn them off in your device’s settings.
        </Typography>
        <Typography gutterBottom variant="h6">
          USE OF YOUR INFORMATION
        </Typography>
        <Typography paragraph>
          Having accurate information permits us to provide you and your
          organization with a efficient, and customized experience.
          Specifically, we may use information collected about you via the
          Website to:
          <ol>
            <li>Map and locate above and below ground assets. </li>
            <li>Assist quality and best practice work efforts.</li>
            <li>
              Compile statistical data and analysis for use internally or with
              your organization.
            </li>
            <li>Create and manage your account.</li>
            <li>Email you regarding your account or order.</li>
            <li>Enable user-to-user communications.</li>
            <li>
              Fulfill and manage purchases, orders, payments, and other transactions related to UTTO devices, mobile applications or websites.
            </li>
            <li>
              Generate a personal profile about you to make future use of UTTO devices, mobile applications or websites more personalized.
            </li>
            <li>Increase the efficiency and operation of UTTO devices, mobile applications or websites.</li>
            <li>
              Monitor and analyze usage and trends to improve your experience with UTTO devices, mobile applications or websites.
            </li>
            <li>Notify you of updates to UTTO devices, mobile applications or websites.</li>
            <li>
              Offer new products, services, devices, mobile applications or websites, and/or recommendations to you.
            </li>
            <li>Perform other business activities as needed.</li>
            <li>
              Prevent fraudulent transactions, monitor against theft, and
              protect against criminal activity.
            </li>
            <li>Process payments and refunds.</li>
            <li>
              Request feedback and contact you about your use of UTTO devices, mobile applications or websites.
            </li>
            <li>Resolve disputes and troubleshoot problems.</li>
            <li>Respond to product and customer service requests.</li>
            <li>Send you a newsletter.</li>
            <li>Process support requests from UTTO devices, mobile applications or websites.</li>
          </ol>
        </Typography>
        <Typography gutterBottom variant="h5">
          DISCLOSURE OF YOUR INFORMATION
        </Typography>
        <Typography paragraph>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </Typography>
        <Typography gutterBottom variant="h6">
          By Law or to Protect Rights
        </Typography>
        <Typography paragraph>
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </Typography>
        <Typography gutterBottom variant="h6">
          Third-Party Service Providers
        </Typography>
        <Typography paragraph>
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </Typography>
        <Typography gutterBottom variant="h6">
          Interactions with Other Users
        </Typography>
        <Typography paragraph>
          If you interact with other users of UTTO devices, mobile applications or websites,
          those users may see your name, profile photo, and descriptions of your activity,
          including sending invitations to other users, chatting with other users and sharing information.
        </Typography>
        <Typography gutterBottom variant="h6">
          Sale or Bankruptcy
        </Typography>
        <Typography paragraph>
          If we reorganize or sell all or a portion of our assets, undergo a
          merger, or are acquired by another entity, we may transfer your
          information to the successor entity. If we go out of business or enter
          bankruptcy, your information would be an asset transferred or acquired
          by a third party. You acknowledge that such transfers may occur and
          that the transferee may decline honor commitments we made in this
          Privacy Policy.
        </Typography>
        <Typography paragraph>
          We are not responsible for the actions of third parties with whom you
          share personal or sensitive data, and we have no authority to manage
          or control third-party solicitations. If you no longer wish to receive
          correspondence, emails or other communications from third parties, you
          are responsible for contacting the third party directly.
        </Typography>
        <Typography gutterBottom variant="h5">
          TRACKING TECHNOLOGIES
        </Typography>
        <Typography gutterBottom variant="h6">
          Cookies and Web Beacons
        </Typography>
        <Typography paragraph>
          We may use cookies, web beacons, tracking pixels, and other tracking
          technologies on UTTO devices, mobile applications or websites to help
          customize them and improve your experience. When you access UTTO devices,
          mobile applications or websites, your personal information is not collected
          through the use of tracking technology. Most browsers are set to accept cookies
          by default. You can remove or reject cookies, but be aware that such action
          could affect the availability and functionality of the UTTO devices, mobile
          applications or websites. You may not decline web beacons. However, they can
          be rendered ineffective by declining all cookies or by modifying your web browser’s
          settings to notify you each time a cookie is tendered, permitting you to accept
          or decline cookies on an individual basis.
        </Typography>
        <Typography gutterBottom variant="h6">
          Website Analytics
        </Typography>
        <Typography paragraph>
          We may also partner with selected third-party vendors such as{' '}
          <Link to="https://support.google.com/analytics/answer/6004245?hl=en">
            Google Analytics
          </Link>{' '}
          and other to allow tracking technologies and remarketing services on UTTO devices,
          mobile applications or websites through the use of first party cookies and third-party
          cookies, to, among other things, analyze and track users’ use of UTTO devices, mobile
          applications or websites, determine the popularity of certain content, and better
          understand online activity. By accessing UTTO devices, mobile applications, or websites,
          you consent to the collection and use of your information by these third-party vendors.
          You are encouraged to review their privacy policy and contact them directly for responses
          to your questions. We do not transfer personal information to these third-party vendors.
          However, if you do not want any information to be collected and used by tracking technologies,
          you can install and/or update your settings for one of the following:
          <br />[
          <Link to="https://tools.google.com/dlpage/gaoptout/">
            Google Analytics Opt-Out Plugin
          </Link>
          ]
        </Typography>
        <Typography paragraph>
          You should be aware that getting a new computer, installing a new
          browser, upgrading an existing browser, or erasing or otherwise
          altering your browser’s cookies files may also clear certain opt-out
          cookies, plug-ins, or settings.
        </Typography>
        <Typography gutterBottom variant="h5">
          THIRD-PARTY WEBSITES
        </Typography>
        <Typography paragraph>
          UTTO devices, mobile applications or websites may contain links to 
          third-party websites and websites of interest, including advertisements
          and external services, that are not affiliated with us. Once you have
          used these links to leave a UTTO website, any information you provide 
          to these third parties is not covered by this Privacy Policy, and we 
          cannot guarantee the safety and privacy of your information. Before 
          visiting and providing any information to any third-party websites, 
          you should inform yourself of the privacy policies and practices (if any)
          of the third party responsible for that website, and should take those 
          steps necessary to, in your discretion, protect the privacy of your 
          information. We are not responsible for the content or privacy and 
          security practices and policies of any third parties, including other
          sites, services or websites that may be linked to or from the website.
        </Typography>
        <Typography gutterBottom variant="h5">
          SECURITY OF YOUR INFORMATION
        </Typography>
        <Typography paragraph>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </Typography>
        <Typography gutterBottom variant="h5">
          CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography paragraph>
          Most web browsers and some mobile operating systems include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected. No uniform technology standard for
          recognizing and implementing DNT signals has been finalized. As such,
          we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be
          tracked online. If a standard for online tracking is adopted that we
          must follow in the future, we will inform you about that practice in a
          revised version of this Privacy Policy.
        </Typography>
        <Typography gutterBottom variant="h5">
          OPTIONS REGARDING YOUR INFORMATION
        </Typography>
        <Typography gutterBottom variant="h6">
          Account Information
        </Typography>
        <Typography paragraph>
          You may at any time review or change the information in your account
          or terminate your account by:
          <ul>
            <li>
              Logging into your account settings and updating your account
            </li>
            <li>Contacting us using the contact information provided below</li>
          </ul>
        </Typography>
        <Typography paragraph>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </Typography>
        <Typography gutterBottom variant="h6">
          Emails and Communications
        </Typography>
        <Typography paragraph>
          If you no longer wish to receive correspondence, emails, or other
          communications from us, you may opt-out by contacting us using the
          contact information provided below.
        </Typography>
        <Typography paragraph>
          If you no longer wish to receive correspondence, emails, or other
          communications from third parties, you are responsible for contacting
          the third party directly.
        </Typography>
        <Typography gutterBottom variant="h5">
          CALIFORNIA PRIVACY RIGHTS
        </Typography>
        <Typography paragraph>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </Typography>
        <Typography paragraph>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, you have the right to request
          removal of unwanted data that you publicly post on the Website. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from our systems.
        </Typography>
        <Typography gutterBottom variant="h5">
          CONTACT US
        </Typography>
        <Typography paragraph>
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </Typography>
        <Typography paragraph>
          IPEG Corporation
          <br />
          5400 Jaeger Road
          <br />
          Naples, Florida 34109 U.S.A
        </Typography>
        <Typography paragraph>
          <a href="tel:+1-239-963-1470">(239) 963 1470</a>
          <br />
          <a href="mailto:info@ipegcorp.com">info@ipegcorp.com</a>
        </Typography>
      </Paper>
    </SlimTemplate>
  );
};

export default PrivacyPolicy;
