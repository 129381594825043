import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar';
import 'typeface-roboto';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as serviceWorker from './serviceWorker';
import App from './app';
import './index.css';

// setup runtime environment
if (window.__env__) {
  const { GOOGLE_ANALYTICS_API_KEY, HOTJAR_HJID, HOTJAR_HJSV } = window.__env__;
  // enable google analytics
  if (GOOGLE_ANALYTICS_API_KEY) {
    ReactGA.initialize(window.__env__?.GOOGLE_ANALYTICS_API_KEY);
  }
  // enable hotjar heatmapping analytics
  if (HOTJAR_HJID && HOTJAR_HJSV) {
    hotjar.initialize(window.__env__?.HOTJAR_HJID, window.__env__.HOTJAR_HJSV);
  }
}

const render = Component => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

render(App);

if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
