import {
  generateFetchDataTypes,
  generateFetchDataActions,
  generatePostDataTypes,
  generatePostDataActions
} from 'ducks/utils';

export const types = {
  locators: generateFetchDataTypes('UTTO/TEAM/LOCATORS'),
  scores: generateFetchDataTypes('UTTO/TEAM/SCORES'),
  scoresHistory: generateFetchDataTypes('UTTO/TEAM/SCORES_HISTORY'),
  scoresAlerts: generateFetchDataTypes('UTTO/TEAM/SCORES_ALERTS'),
  siteReports: generateFetchDataTypes('UTTO/TEAM/SITE_REPORTS'),
  activeUsers: generateFetchDataTypes('UTTO/TEAM/ACTIVE_USERS'),
  siteReport: generateFetchDataTypes('UTTO/TEAM/SITE_REPORT'),
  siteReportAlerts: generateFetchDataTypes('UTTO/TEAM/SITE_REPORT_ALERTS'),
  activityHistory: generateFetchDataTypes('UTTO/TEAM/ACTIVITY_HISTORY'),
  teamActivityHistograms: generateFetchDataTypes(
    'UTTO/TEAM/ACTIVITY/ACTIVITY_HISTOGRAMS'
  ),
  alerts: {
    ...generateFetchDataTypes('UTTO/TEAM/ALERTS'),
    DISMISS: 'UTTO/TEAM/ALERTS_DISMISS'
  },
  alertResolve: generatePostDataTypes('UTTO/TEAM/ALERT_RESOLVE'),
  alertsSettings: generateFetchDataTypes('UTTO/TEAM/ALERTS_SETTINGS'),
  saveAlertsSettings: generatePostDataTypes('UTTO/TEAM/ALERTS_SETTINGS_SAVE')
};

export const teamActivityHistogramsActions = generateFetchDataActions(
  types.teamActivityHistograms
);
export const activityHistoryActions = generateFetchDataActions(
  types.activityHistory
);
export const locatorActions = generateFetchDataActions(types.locators);
export const scoresActions = generateFetchDataActions(types.scores);
export const scoresAlertsActions = generateFetchDataActions(types.scoresAlerts);
export const scoresHistoryActions = generateFetchDataActions(
  types.scoresHistory
);
export const siteReportsActions = generateFetchDataActions(types.siteReports);
export const activeUsersActions = generateFetchDataActions(types.activeUsers);
export const siteReportActions = generateFetchDataActions(types.siteReport);
export const siteReportAlertsActions = generateFetchDataActions(
  types.siteReportAlerts
);
export const alertActions = {
  ...generateFetchDataActions(types.alerts)
};
export const alertResolveActions = generatePostDataActions(types.alertResolve);
export const alertsSettingsActions = generateFetchDataActions(
  types.alertsSettings
);
export const saveAlertsSettingsActions = generatePostDataActions(
  types.saveAlertsSettings
);
