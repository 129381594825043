import {
  createReducer,
  combineReducersWithDivisionChangeReset,
  generateFetchDataReducers,
  FETCH_DATA_INITIAL_STATE
} from 'ducks/utils';
import { types } from './actions';

const teams = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.teams)
);

const studies = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.studies)
);

const damages = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.damages)
);

export default combineReducersWithDivisionChangeReset({
  teams,
  studies,
  damages
});
