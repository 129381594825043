import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import SlimTemplate from 'pages/slimTemplate';
import useStyles from './styles';

const PrivacyPolicyUK = () => {
  const classes = useStyles();

  return (
    <SlimTemplate showMenu title="Privacy Policy">
      <Paper className={classes.documentWrapper}>
        <Typography variant="h4" className={classes.formTitle}>
          Privacy Policy
        </Typography>
        <Typography gutterBottom variant="h6">
          Last updated: May 01, 2024
        </Typography>

        <Typography paragraph>
          UTTO Technologies and its affiliated entities (collectively “UTTO”) respect individual privacy and value the confidence of its customers, business partners and others. UTTO is committed to upholding the highest ethical standards in its business practices, and has long held this commitment as a fundamental element of the UTTO Leadership Standards. Accordingly, UTTO adheres to strict standards in connection with the collection, use and disclosure of Personal Information, consistent with the laws of the countries in which UTTO conducts business.
        </Typography>

        <Typography paragraph>
          This Privacy Policy (“Policy”) applies to this website or to any website that includes a link or reference to this Policy (“Site(s)”) and describes how we handle Personal Information and the choices available to you regarding collection, use, access and how to update and correct your Personal Information.
        </Typography>

        <Typography gutterBottom variant="h5">
          Personal Information we collect
        </Typography>
        <Typography paragraph>
          We collect data, including Personal Information that you provide on our Site and as you use the Site. “Personal Information” includes information that can be used to identify you as a natural person, directly or indirectly, in particular in combination with other information available to us such as an identification number, online identifier or one or more factors specific to your identity as a natural person and may include your:

          <ul>
            <li>name</li>
            <li>job title</li>
            <li>address</li>
            <li>phone number</li>
            <li>fax number</li>
            <li>e-mail address</li>
          </ul>
        </Typography>

        <Typography paragraph>
          Each visitor to the Site may be identified by cookies or similar technologies (see our Cookie Policy) as well as by an IP address during their visit. The information we collect depends on how you use our Site.
        </Typography>

        <Typography paragraph>
          UTTO aims at keeping the Personal Information we hold is accurate and up-to-date. Please ensure that the Personal Information you communicate to us is accurate and up-to-date.
        </Typography>

        <Typography paragraph>
          If you fail to provide certain personal data when requested, we may not be able to perform the contract we have entered into with you (such as providing a product or service you requested), or we may be prevented from complying with our legal obligations.
        </Typography>

        <Typography gutterBottom variant="h5">
          How we use your Personal Information
        </Typography>
        <Typography paragraph>
          We only collect, use and store your Personal Information for the purposes of operating our business, delivering, improving and customizing our Site as well as products and services we provide, sending direct marketing and other communications related to our business, products and services, providing you with information, products and services that you request from us, and for other legitimate purposes permitted by applicable law. As the case may be, we will provide you with the basis of such legitimate purposes upon its occurrence, prior to implementation of the related processing.
        </Typography>

        <Typography paragraph>
          UTTO collects Personal Information for a variety of purposes, including the following:
          <ul>
            <li>Performance of a contract executed between you and UTTO;</li>
            <li>Providing you with our product(s) and/or service(s);</li>
            <li>Registering a UTTO product UTTO;</li>
            <li>Respond to an inquiry from you or sending communications to you for direct marketing from UTTO or customer survey purposes, either directly from UTTO or from our third-party partners;</li>
            <li>Providing you with customer service and technical support;</li>
            <li>Personalize your experience on the Site;</li>
            <li>Improving the operation of the Site and/or help adjust the content of the Site to the general geographic location of you and for reporting purposes.</li>
          </ul>

          Please note that we do not share such information with third parties for their direct marketing purposes.
        </Typography>

        <Typography paragraph>
          Where required or permitted by applicable law, we also use or disclose your Personal Information to limited third parties when we believe, in good faith, that such use or disclosure is reasonably necessary to (i) comply with the legal obligation bearing on UTTO, (ii) enforce or apply the terms of any agreement to which UTTO is a party, or (iii) protect the rights, property or safety of UTTO, UTTO’s clients, or others.
        </Typography>

        <Typography gutterBottom variant="h5">
          How we share your Personal Information
        </Typography>
        <Typography paragraph>
          Any Personal Information collected via the Site or when you contact UTTO’s sales, customer or technical support, will be processed in the United States by UTTO. We may share your personal Information with our affiliated entities and other third-party business partners, providers, vendors or contractors acting on our behalf and under our instructions, whether located inside and outside of the European Union (“EU”) or the Economic Area (“EEA”), for the purposes of operating our business, delivering, improving, and customizing our Site as well as our products and services, sending direct marketing and other communications related to our business, and for other legitimate purposes permitted by applicable law or otherwise,] and when needed, with your consent.
        </Typography>

        <Typography paragraph>
          Therefore, we may share Personal Information in the following ways:

          <ul>
            <li>Within UTTO and any of our affiliated entities located inside or outside of the EU/EEA, for purposes of data processing or storage.</li>
            <li>With UTTO’s business partners, vendors, authorized third-party agents or contractors to provide our products and services or facilitate transactions. Examples include, but are not limited to: processing or orders, hosting Sites and providing support.</li>
            <li>In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by another company.</li>
            <li>As the case may be, for further protection of your privacy, UTTO will share your Personal Information in aggregated and/or de-identified form that cannot be used to identify you</li>
            <li>Where mandated or permitted by applicable law, regulation or legal process, we will disclose your Personal Information to law enforcement officials, government authorities or other third parties, located inside and outside of the EU/EEA, where such disclosure is in accordance with due process of law and binding upon UTTO, to the extent necessary to comply with legal process or meet national security requirements, protect the rights, property, or safety of UTTO, its business partners, you, or others.</li>
          </ul>
        </Typography>

        <Typography gutterBottom variant="h5">
          Transfer, processing and storage of Personal Information
        </Typography>

        <Typography paragraph>
          As a global organization with affiliated entities located inside and outside of the EU/EEA and business processes in operation across borders, we may transfer your Personal Information to UTTO’s headquarters in the United States of America, to any UTTO affiliate entity worldwide or to third-party business partners, providers, vendors, or contractors, that are located inside or outside of the EU/EEA, for purposes described in the section “How we share your Personal Information” above, including for the purpose of data processing or storage on behalf and under the control of UTTO.
        </Typography>

        <Typography paragraph>
          UTTO will perform any cross-borders transfer in compliance with applicable privacy and data protection regulations, including Regulation (EU) 2016/679 of 27 April 2016 on the protection of personal data (“GDPR”). Where mandated by applicable law, to ensure that your personal data does receive an adequate level of protection, UTTO implements the appropriate measures to ensure that your Personal Information is treated across UTTO affiliated entities and by the aforementioned third parties in a way that is consistent with and which respects the applicable privacy and data protection laws. Measures consist of Standard Contractual Clauses.
        </Typography>

        <Typography gutterBottom variant="h5">
          Links to third-parties’ Sites
        </Typography>

        <Typography paragraph>
          The Site may contain links to third-party Sites that are operated by companies outside of our control. Your activities on those third-party Sites will be solely governed by the privacy policies and procedures of those third parties. UTTO takes no responsibility whatsoever for the content or information practices of such third parties and encourage you to review the privacy polices of these third parties before disclosing any information on their Sites.
        </Typography>

        <Typography gutterBottom variant="h5">
          Security and confidentiality of your Personal Information
        </Typography>

        <Typography paragraph>
          The security and confidentiality of your Personal Information is important to us. We implement appropriate physical, administrative, and technical measures and safeguards designed to protect your Personal Information from loss, theft, misuse, alteration, unlawful or unauthorized access, use, or disclosure.
        </Typography>

        <Typography paragraph>
          UTTO also relies on reasonable measures to safeguard Personal Information in our possession, including:

          <ul>
            <li>Enforcement of group-wide policies and procedures on Personal Information use, security and confidentiality;</li>
            <li>Restricting access to Personal Information to only those employees and third-party service providers on a “need to know” basis and under strict confidentiality requirements;</li>
            <li>Use of technologies to safeguard data during transmission, such as SSL encryption for some of the data that you provide via the Site and using appropriate security measures to safeguard the data we collect and possess;</li>
            <li>Use of industry-standard measures and processes for detecting and responding to inappropriate attempts to breach our computer systems and networks;</li>
          </ul>
        </Typography>

        <Typography paragraph>
          The Internet and any communication thereon cannot be guaranteed to be secure at all times, and we cannot ensure or warrant the security of any Personal Information you provide us through this technology. In this regard, we have procedures to deal with any suspected data security breach and will notify you and any regulator of a suspected breach where required to do so.
        </Typography>

        <Typography gutterBottom variant="h5">
          Retention of Personal information
        </Typography>

        <Typography paragraph>
          We will retain your Personal Information for only as long as needed to fulfill the purposes for which it is collected. We will retain and use your Personal Information as necessary to comply with our business requirements, legal obligations, resolve disputes, protect our assets and enforce agreements to which UTTO (or any of our affiliated entity) is a party.
        </Typography>

        <Typography gutterBottom variant="h5">
          Your Rights
        </Typography>

        <Typography paragraph>
        You have the right to access, free of charge, obtain a paper or electronic copy, review, correct and update all your Personal Information stored by us, notably to confirm its accuracy. Subject to any relevant legal requirements and exemptions, you may also oppose to or limit the processing or your Personal Information or request that certain of your Personal Information be deleted from our files.
        </Typography>

        <Typography paragraph>
        If you reside within the EU, you may also exercise you right to portability of your Personal Information where the lawful basis for the processing is (i) (a) a contract or (b) your consent and (ii) by automated means. Please note that such a request could be limited to the sole Personal Information you provided us with or that we hold at that given time and subject to any relevant legal requirements and exemptions, including identity verification procedures.
        </Typography>

        <Typography paragraph>
        UTTO will make reasonable efforts to comply with the aforementioned requests, unless such requests are prohibited by law, or there is a legitimate purpose to retain your Personal Information, in which case we will inform you without undue delay. We reserve the right to verify your identity before any request relating to your Personal Information processed by us. Please direct any questions about your Personal Information using the contact details provided at the end of this Policy.
        </Typography>

        <Typography paragraph>
        Any individual who wishes to opt out can do so freely and at any time by contacting UTTO at using the contact details provided at the end of this Policy, further to the information process relating to the new purpose or clicking on the link within each of our marketing communications.
        </Typography>

        <Typography gutterBottom variant="h5">
          Children’s Privacy
        </Typography>

        <Typography paragraph>
          UTTO encourages parents and guardians to take an active role in their children’s online activities. UTTO does not sell or offer its products and services to children. As such, our Sites are designed for adult user interaction. We do not intentionally collect Personal Information from children under the age of 18. If you are a parent or legal guardian of a minor under the age of 18 and believe that the minor has disclosed Personal Information to us, please contact us using the contact details provided in this Privacy Policy.
        </Typography>

        <Typography gutterBottom variant="h5">
          Choice and Opt-Out
        </Typography>

        <Typography paragraph>
          Subject to your choices, we may also send you direct marketing communications via email. In addition to the “unsubscribe” link contained in each of our e-mail marketing communications, you may opt-out freely and at any time from receiving UTTO newsletters or other e-mail marketing communications from UTTO by sending an e-mail to privacy@utto.com.
        </Typography>

        <Typography paragraph>
          We may send you service communications relating to the products or services we provide you via email (e.g. to inform you about changes to the product or service you requested from us, revisions of our terms and conditions or this Policy). As such service communications are necessary for the purposes of providing you with our products or services or complying with out legal obligations, you will not be able to opt-out from receiving them.
        </Typography>

        <Typography gutterBottom variant="h5">
          Updates to this Policy
        </Typography>

        <Typography paragraph>
          We reserve the right to update this Privacy Policy from time to time in order to reflect any changes to our products or services or to comply with changes in our legal and/or regulatory obligations. We will post the revised version on the Site, with an updated revision date. Where such changes to our Privacy Policy are substantial, we will also notify you by other means prior to the changes taking effect, such as by sending you an email notification. By continuing to use our Site thirty days after such revisions are in effect, you will be deemed to accept and agree to the revisions and to abide by them.
        </Typography>


        <Typography gutterBottom variant="h5">
          CONTACT US
        </Typography>
        <Typography paragraph>
          If you have any question or would like to enquire about UTTO’s privacy practices and this Policy, the use of your Personal Information, object to or restrict UTTO’s use of your Personal Information for direct marketing purpose or request deletion or correction of such Personal Information, please contact us at:
        </Typography>
        <Typography paragraph>
          UTTO Technologies
          <br />
          Attn: Privacy Center
          <br />
          Address: 1.04 Proto Abbott's Hill, Gateshead, United Kingdom, NE8 3DF
          <br />
          Email: <a href="mailto:privacy@utto.com">privacy@utto.com</a>
          <br />
          Phone: <a href="tel:+1-239-963-1470">(239) 963 1470</a>
          <br />
        </Typography>
        <Typography paragraph>
          If you are not satisfied with our answer or the way we process your Personal Information pursuant to this Privacy Policy, you may also have the right to lodge a complaint with a data protection authority or a Court of competent jurisdiction. If you reside within the European Union, a list of national data protection authorities can be found <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">here</a>
        </Typography>
      </Paper>
    </SlimTemplate>
  );
};

export default PrivacyPolicyUK;
