import { useMemo } from 'react';

const usePeriodFromParams = (from, per) =>
  useMemo(() => {
    if (per === 'day') {
      return { period: 'oneWeek' };
    }
    if (per === 'month') {
      return { period: 'oneYear' };
    }
    if (from.fromNow() === '3 months ago') {
      return { period: 'threeMonths' };
    }
    if (from.fromNow() === 'a month ago') {
      return { period: 'oneMonth' };
    }
  }, [from, per]);

export default usePeriodFromParams;
