import { combineReducers } from 'redux';
import tokenStorage from 'services/tokenStorage';
import {
  createReducer,
  POST_DATA_INITIAL_STATE,
  generatePostDataReducers
} from 'ducks/utils';
import { types } from './actions';

const CURRENT_TEAM_INITIAL_STATE = { team: '', div: '' };
const CURRENT_DIVISION_INITIAL_STATE = { div: '' };

const LOGIN_INITIAL_TOKEN = tokenStorage.get();
const LOGIN_INITIAL_STATE = {
  pending: false,
  success: false,
  error: null,
  data: {
    token: LOGIN_INITIAL_TOKEN?.token,
    user: LOGIN_INITIAL_TOKEN?.user
  }
};

const SNACKBAR_INITIAL_STATE = { open: false, message: '', variant: '' };

const loginReducers = {
  ...generatePostDataReducers(types.login),
  [types.changeDetails.SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        ...action.payload
      }
    }
  }),
  [types.LOGOUT]: () => ({
    pending: false,
    success: false,
    error: null,
    data: {
      token: null,
      user: null
    }
  })
};

const login = createReducer(LOGIN_INITIAL_STATE, loginReducers);

const changeDetails = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducers(types.changeDetails, true)
);

const createAccount = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducers(types.createAccount, true)
);

const currentTeam = createReducer(CURRENT_TEAM_INITIAL_STATE, {
  [types.changeCurrentTeam]: (state, action) => action?.payload || state,
  [types.LOGOUT]: () => CURRENT_TEAM_INITIAL_STATE
});

const currentDivision = createReducer(CURRENT_DIVISION_INITIAL_STATE, {
  [types.changeCurrentDivision]: (state, action) => action?.payload || state,
  [types.LOGOUT]: () => CURRENT_DIVISION_INITIAL_STATE
});

const snackbar = createReducer(SNACKBAR_INITIAL_STATE, {
  [types.openSnackbar]: (state, action) => ({
    ...state,
    open: true,
    message: action.payload.message,
    variant: action.payload.variant
  }),
  [types.closeSnackbar]: (state, action) => ({
    ...state,
    ...SNACKBAR_INITIAL_STATE
  })
});

export default combineReducers({
  login,
  changeDetails,
  createAccount,
  currentTeam,
  currentDivision,
  snackbar
});
