import { useState, useLayoutEffect } from 'react';

/**
 * Custom hook for retrieving size of component's DOM element at runtime
 *
 * USAGE EXAMPLE:
 *
 * const elementRef = useRef();
 * const { width, height } = useComponentSize(elementRef);
 * doSomethingWithSize(width, height);
 * // don't forget to attach the ref to a DOM element
 */
const useComponentSize = ref => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const resize = () =>
      setSize({
        width: ref.current?.offsetWidth,
        height: ref.current?.offsetHeight
      });

    // calculate size immediately and add listeners to recalculate on resize
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [ref, setSize]);

  return size;
};

export default useComponentSize;
