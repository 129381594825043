import { PERMISSIONS_WEIGHT } from 'config';

export const getOverwritePermissionOnly = permissions => {
  const permissionsToOverwrite = permissions.filter(
    permission => PERMISSIONS_WEIGHT[permission]
  );

  if (!permissionsToOverwrite.length) {
    return permissions;
  }

  const strongerPermission = permissionsToOverwrite.sort(
    (x, y) => PERMISSIONS_WEIGHT[y] - PERMISSIONS_WEIGHT[x]
  )[0];

  const generalPermissions = permissions.filter(
    permission => PERMISSIONS_WEIGHT[permission] === undefined
  );

  return [strongerPermission, ...generalPermissions];
};
