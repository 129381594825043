import { createSelector } from 'reselect';
import { getOverwritePermissionOnly } from 'utils';

export const getLogin = state => state.auth.login;
export const getToken = state => state.auth.login?.data?.token;
export const getUser = state => state.auth.login?.data?.user;
export const getOrg = state => state.auth.login?.data?.user?.org;
export const getCurrentTeam = state => state.auth.currentTeam;
export const getCurrentDivision = state => state.auth.currentDivision;
export const getChangeDetails = state => state.auth.changeDetails;
export const getCreateAccount = state => state.auth.createAccount;
export const getSnackbar = state => state.auth?.snackbar;

export const getSnackBarStatus = createSelector(
  [getSnackbar],
  ({ open, message, variant }) => ({ open, message, variant })
);

export const getIsLoggedIn = createSelector([getToken], token =>
  Boolean(token)
);

export const getOrgDivTeam = createSelector(
  [getOrg, getCurrentTeam, getCurrentDivision],
  (org, { team, div }, { div: division }) => ({
    org,
    div: div || division,
    team
  })
);

export const getOrgDiv = createSelector(
  [getOrg, getCurrentDivision],
  (org, { div }) => ({ org, div })
);

export const getName = createSelector([getUser], user =>
  user ? `${user.firstname} ${user.lastname}` : null
);

export const getUserId = createSelector([getUser], user => user?.ac_id);

export const getTokenBody = createSelector([getToken], token => {
  // test that token exists and contains a payload section
  const payload = token?.split('.')?.[1];
  // decode the payload
  return payload ? JSON.parse(atob(payload)) : null;
});

export const getPermissionSubjects = createSelector(
  [getTokenBody],
  tokenBody => tokenBody?.user?.requested_privilege_subjects || {}
);

export const getPermissions = createSelector(
  [getTokenBody, getPermissionSubjects],
  (tokenBody, permissionSubjects) => {
    const selfPermissions = tokenBody?.user?.self_privileges || [];
    const permissionsWithSubjects = Object.entries(permissionSubjects)
      .filter(([perm, subj]) => Boolean(subj.length))
      .map(([perm, subj]) => perm);

    const uniquePermissions = Array.from(
      new Set([...selfPermissions, ...permissionsWithSubjects])
    );

    return uniquePermissions;
  }
);

export const getOverwritePermissions = createSelector(
  [getPermissions],
  permissions => {
    return getOverwritePermissionOnly(permissions);
  }
);
