import { useCallback } from 'react';

const useScrollToRef = ref =>
  useCallback(() => {
    const elementTop = ref.current?.offsetTop;
    const elementCenterOffset = ref.current?.offsetHeight / 2;
    const elementCenter = elementTop + elementCenterOffset;

    const windowCenterOffset = window.innerHeight / 2;

    // this gets the center of the element scrolled to the center of the screen
    const offset = elementCenter - windowCenterOffset;
    // try using the new browser API, if it fails, use the old one
    try {
      window.scrollTo({ top: offset, left: 0, behaviour: 'smooth' });
    } catch (e) {
      window.scrollTo(0, offset);
    }
  }, [ref]);

export default useScrollToRef;
