import { useMemo } from 'react';

import { formatDuration, formatDistance } from 'utils/formatters';

const add = (x, y) => {
  if (isNaN(y)) {
    return x;
  }
  return x + y;
};

export const sumArray = arr => arr.reduce(add, 0);

// TODO make this less of a mess
const useStatsAggregator = (activityHistory, locatorIds = [], locators) =>
  useMemo(() => {
    if (!activityHistory || !locatorIds) {
      return null;
    }
    let distanceArray = [];
    let averageDistanceArray = [];
    let timeTakenArray = [];
    let averageTimeTakenArray = [];
    let numberOfSessionsArray = [];
    let numberOfDaysArray = [];
    let numberOfLocatePointsArray = [];
    activityHistory.user_scores.forEach(item => {
      if (locatorIds.includes(item.userId) || !locatorIds?.length) {
        item.values.forEach(value => {
          distanceArray.push(value?.metrics?.totalDistance);
          averageDistanceArray.push(value?.metrics?.averageDistance);
          timeTakenArray.push(value?.metrics?.totalDuration);
          averageTimeTakenArray.push(value?.metrics?.averageDuration);
          numberOfSessionsArray.push(value?.metrics?.numberOfSessions);
          numberOfDaysArray.push(value?.metrics?.totalDays);
          numberOfLocatePointsArray.push(value?.metrics?.totalLpPoints);
        });
      }
    });
    const totalDistance = distanceArray.reduce(add, 0); // in metres
    const totalNumberOfSessions = sumArray(numberOfSessionsArray);
    const averageDistanceWalked = totalDistance / totalNumberOfSessions || 0;
    const totalDuration = sumArray(timeTakenArray);
    const averageDuration = totalDuration / totalNumberOfSessions || 0;
    const averageSessionsPerLocator =
      (locatorIds?.length || locators?.length) > 0
        ? Math.floor(
            totalNumberOfSessions / (locatorIds?.length || locators?.length)
          )
        : null;
    const totalNumberOfDays = sumArray(numberOfDaysArray);
    const durationPerDay = Math.round(totalDuration / totalNumberOfDays);
    const totalNumberOfLocatePoints = sumArray(numberOfLocatePointsArray);
    const distanceBetweenPoints = totalDistance / totalNumberOfLocatePoints;
    return {
      distanceWalked: formatDistance(totalDistance, { feetOnly: true }),
      averageDistanceWalked: formatDistance(averageDistanceWalked, {
        feetOnly: true
      }),
      totalTimeTaken: formatDuration(totalDuration),
      averageTimeTaken: formatDuration(averageDuration),
      totalNumberOfSessions: totalNumberOfSessions,
      averageSessionsPerLocator: averageSessionsPerLocator,
      timeOnSite: formatDuration(durationPerDay),
      distanceBetweenPoints: formatDistance(distanceBetweenPoints)
    };
  }, [activityHistory, locatorIds, locators]);

export default useStatsAggregator;
