import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { Header } from 'utto-react-components';
import { Toolbar, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useReduxSelector } from 'hooks';
import history from 'services/history';
import { getName } from 'ducks/auth/selectors';
import { ReactComponent as Logo } from 'logo-white.svg';
import { ReactComponent as LogoIcon } from 'uttoLogo.svg';
import { useAccountMenuButtons } from './template/hooks';
import Footer from './footer';
import useStyles from './styles';

const SlimTemplate = ({ title, children, showMenu = false }) => {
  const classes = useStyles();
  const accountButtons = useAccountMenuButtons();
  const name = useReduxSelector(getName);
  const theme = useTheme();
  const screen_xs = useMediaQuery(theme.breakpoints.only('xs'));
  const onLogoClick = useCallback(() => history.push('/'), []);

  return (
    <div className={classes.page}>
      {title && (
        <Helmet>
          <title>UTTO | {title}</title>
        </Helmet>
      )}
      <Header
        logo={
          screen_xs ? (
            <LogoIcon className={classes.logo} onClick={onLogoClick} />
          ) : (
            <Logo className={classes.logo} onClick={onLogoClick} />
          )
        }
        classes={{
          bar: classes.header
        }}
        name={showMenu ? name : null}
        menuItems={
          showMenu
            ? accountButtons.map((item, index) => (
                <MenuItem key={index} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))
            : null
        }
      />
      <main className={classnames(classes.content, 'fix-scroll-padding')}>
        <Toolbar />
        {children}
      </main>
      <Footer className={classes.footer} />
    </div>
  );
};

export default SlimTemplate;
