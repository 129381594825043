import pino from 'pino';
import { writeLog } from './api';

const logger = pino({
  level: 'silent',
  base: {
    // these get added to all log messages
    userAgent: navigator.userAgent,
    url: window.location.href
  },
  browser: {
    serialize: true,
    transmit: {
      level: 'warn',
      send: (level, logEvent) => {
        if (console[level]) console[level](...logEvent.messages);
        writeLog(logEvent);
      }
    }
  }
});

// log unhandled exceptions with a child handler setting context
window.addEventListener('error', errorEvent => {
  logger
    .child({ fatal: true })
    .error('UNHANDLED ERROR', errorEvent.message, errorEvent.error);
  errorEvent.preventDefault();
});

// log unhandled promise rejections with a child handler setting context
window.addEventListener('unhandledrejection', rejectionEvent => {
  logger.error('UNHANDLED PROMISE REJECTION', rejectionEvent.reason);
  rejectionEvent.preventDefault();
});

// convenience method to enable logging to console in production
window.__ENABLE_LOGGING__ = () => (logger.level = 'trace');

export default logger;
