import { makeStyles } from '@material-ui/styles';

const styles = theme => ({
  addButtonContained: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.variables.gridGap
  },
  addButtonText: {
    color: 'white'
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginTop: '1rem'
  },
  expansionPanel: {
    borderRadius: '4px',
    marginTop: theme.variables.gridGap
  },
  form: {
    marginBottom: theme.variables.formSpacer,
    display: 'flex',
    flexDirection: 'column'
  },
  formContainer: {
    transition: 'opacity 1s',
    //  flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    visibility: 'hidden',
    maxWidth: '20rem',
    minWidth: '20rem'
    // [theme.breakpoints.down('md')]: {
    //   marginTop: theme.variables.gridGap,
    //   marginLeft: '0rem'
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: theme.variables.gridGap,
    //   marginTop: '0rem'
    // }
  },
  formSubtitle: {
    marginTop: '2rem'
  },
  formFieldDouble: {
    flex: 0.48,
    minWidth: '10rem'
  },
  formFieldContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  icon: {
    color: theme.palette.primary.main,
    padding: '0px'
  },
  submitBtn: {
    marginTop: theme.variables.formSpacer
  },
  selected: {
    opacity: 1,
    visibility: 'visible',
    order: 1
  },
  upperMargin: {
    marginTop: '1rem'
  },
  notSelected: {
    opacity: 0,
    order: 2
  }
});

export default makeStyles(styles, { name: 'forms' });
