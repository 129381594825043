import { useEffect, useCallback } from 'react';
import { useReduxSelector, useReduxAction } from 'hooks';
import equal from 'deep-equal';

/**
 * Custom hook to fetch data from redux or dispatch an action to fetch the data
 *
 * Returns an array so multiple calls can destructure different variable names
 *
 * USAGE EXAMPLE:
 *   const [scores, pending, error, retry] = useFetchedData(
 *     getScores,
 *     scoreActions.request,
 *     { from: '2019-01-02T00:00:00Z', to: '2019-02-02T00:00:00Z' }
 *   );
 */
const useFetchedData = (selector, requestAction, requestPayload) => {
  const actionDispatcher = useReduxAction(requestAction);
  const result = useReduxSelector(selector);
  const { data, pending, error, params } = result;

  // memoise function to fetch the data for the given action & payload
  const fetchData = useCallback(() => actionDispatcher(requestPayload), [
    actionDispatcher,
    requestPayload
  ]);

  // re-fetch the data if it does not exist or the action/arguments changes
  useEffect(() => {
    if (!data || !equal(requestPayload, params)) fetchData();
  }, [data, fetchData, params, requestPayload]);

  // return data, pending and error with fetchData function for retrying
  return [
    !equal(requestPayload, params) ? null : data, //check if the params are not the same do not return from the store
    pending,
    error,
    fetchData
  ];
};

export default useFetchedData;
