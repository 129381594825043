import { makeStyles } from '@material-ui/styles';

const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  header: {
    position: 'fixed'
  },
  content: {
    padding: theme.variables.gridGap,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.variables.footerHeight,
    height: `calc(100vh - ${theme.variables.footerHeight})`,
    overflow: 'auto',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  footer: {
    height: theme.variables.footerHeight
  },
  logo: {
    cursor: 'pointer',
    height: '3rem'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '40rem',
    padding: theme.variables.cardPadding
  },
  formTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    paddingBottom: '1rem'
  },
  returnButton: {
    marginTop: '1rem'
  },
  dialogContainer: {
    width: '100%',
    marginTop: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0
    }
  },
  forgotPasswordHelp: {
    marginTop: '0.5rem'
  },
  resetPasswordHelp: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  documentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.variables.cardPadding,
    margin: '0 auto',
    maxWidth: '80%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'initial'
    }
  }
});

export default makeStyles(styles, { name: 'Pages' });
