import React from 'react';
import clsx from 'clsx';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getSnackBarStatus } from 'ducks/auth/selectors';
import { closeSnackbar } from 'ducks/auth/actions';

const UttoSnackbarContent = props => {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

const UttoSnackbar = props => {
  const { open, message, variant } = useReduxSelector(getSnackBarStatus);
  const dispatchCloseSnackbar = useReduxAction(closeSnackbar);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatchCloseSnackbar();
  };
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <UttoSnackbarContent
        variant={variant}
        message={message}
        onClose={handleClose}
      ></UttoSnackbarContent>
    </Snackbar>
  );
};

export default UttoSnackbar;
