import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import reducers from './ducks';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

let sagaTasks = sagas.map(saga => sagaMiddleware.run(saga));

if (module.hot) {
  module.hot.accept('./ducks', () => {
    const NewReducers = require('./ducks').default;
    store.replaceReducer(NewReducers);
  });
  module.hot.accept('./sagas', () => {
    const NewSagas = require('./sagas').default;
    sagaTasks.forEach(task => task.cancel());
    sagaTasks = NewSagas.map(saga => sagaMiddleware.run(saga));
  });
}

export default store;
