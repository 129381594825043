import * as R from 'ramda';
import { HEX_ELEVATION_DOMAIN, HEX_COLORS } from './config';

// Get the LonLat position of a session
export const getPosition = session => [session.geo_point.lon, session.geo_point.lat];

// Get the duration in seconds of a session
export const getDuration = session => parseInt(session.duration);

// Predicate to test if a session key is a valid metric
const isMetricKey = (value, key) => key.endsWith('_score');

// Pipeline to calculate the overall score for a session
export const calculateOverallScore = R.compose(
  R.mean,
  R.filter(v => v !== null),
  R.values,
  R.pickBy(isMetricKey)
);

export const getColor = R.compose(
  color => {
    if (color < 40) {
      return HEX_COLORS[0];
    } else if (color > 70) {
      return HEX_COLORS[2];
    } else {
      return HEX_COLORS[1];
    }
  },
  calculateOverallScore
);

// Pipeline to calculate the color for a set of sessions
export const calculateColor = R.compose(
  R.head,
  R.sort((a, b) => a - b),
  R.map(calculateOverallScore)
);

export const calculateSingleElevation = R.compose(
  R.apply(R.clamp, HEX_ELEVATION_DOMAIN),
  session =>
    // if session is bad, force max elevation
    calculateOverallScore(session) < 40
      ? HEX_ELEVATION_DOMAIN[1]
      : getDuration(session)
);

// Pipeline to calculate the elevation for a set of sessions
// NOTE: elevation needs clamping within domain,
//       any values outside domain won't be displayed
export const calculateElevation = R.compose(
  calculateSingleElevation,
  R.head // only use first session in hex bin
);
