import { useMemo } from 'react';

const METRIC_FIELD_MAPPINGS = {
  aim_score: 'aim',
  centering_score: 'centering',
  compass_score: 'compass',
  frequency_score: 'frequency',
  current_score: 'current',
  depth_score: 'depth',
  gain_score: 'gain',
  lift_score: 'lift',
  motion_score: 'motion',
  null_score: 'null',
  //  overall: 'overall',
  peak_score: 'peak',
  swing_score: 'swing',
  twist_score: 'twist',
  vertical_score: 'vertical'
};

const useSiteReportMetrics = siteReport =>
  useMemo(
    () =>
      Object.entries(METRIC_FIELD_MAPPINGS).map(([dataKey, newKey]) => ({
        metric: newKey,
        score: siteReport?.[dataKey]
      })),
    [siteReport]
  );

export default useSiteReportMetrics;
