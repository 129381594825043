import { useMemo, useCallback } from 'react';
import { useQueryString } from 'hooks';

export const useSortKey = (querystringKey, initialKey, initialOrder) => {
  const [sort, setSort] = useQueryString(querystringKey, [
    initialKey,
    initialOrder
  ]);
  const [sortKey, sortOrder] = sort;

  const onChangeSort = useCallback(
    key => {
      // if the key is the same and order is descending, switch to ascending
      // otherwise use whatever was clicked in descending
      const newOrder = key === sortKey && sortOrder === 'desc' ? 'asc' : 'desc';
      setSort([key, newOrder]);
    },
    [sortKey, sortOrder, setSort]
  );

  return { sortKey, sortOrder, onChangeSort };
};

export const useSortKeyIn = (
  querystringKey,
  initialKey,
  initialOrder,
  keys
) => {
  const { sortKey, sortOrder, onChangeSort } = useSortKey(
    querystringKey,
    initialKey,
    initialOrder
  );

  const handleChangeSort = useCallback(
    key => {
      if (keys.includes(key)) {
        onChangeSort(key);
      }
    },
    [keys, onChangeSort]
  );

  return { sortKey, sortOrder, onChangeSort: handleChangeSort };
};

const useTableSort = (data, numericColumnKeys, sortKey, sortOrder) =>
  useMemo(
    () =>
      [...data]
        .sort((a, b) =>
          sortOrder === 'asc'
            ? a[sortKey] === null
              ? -1
              : b[sortKey] === null
              ? 1
              : 0
            : a[sortKey] === null
            ? 1
            : b[sortKey] === null
            ? -1
            : 0
        )
        .sort(
          numericColumnKeys.includes(sortKey)
            ? sortOrder === 'asc'
              ? (a, b) => a[sortKey] - b[sortKey]
              : (a, b) => b[sortKey] - a[sortKey]
            : sortOrder === 'asc'
            ? (a, b) => b[sortKey]?.localeCompare(a[sortKey])
            : (a, b) => a[sortKey]?.localeCompare(b[sortKey])
        ),
    [data, numericColumnKeys, sortKey, sortOrder]
  );

const useTableSorter = (
  querystringKey,
  initialSortKey,
  initialSortOrder,
  numericColumnKeys,
  data
) => {
  const { sortKey, sortOrder, onChangeSort } = useSortKey(
    querystringKey,
    initialSortKey,
    initialSortOrder
  );
  const sortedData = useTableSort(
    data || [],
    numericColumnKeys,
    sortKey,
    sortOrder
  );
  return { sortedData, sortKey, sortOrder, onChangeSort };
};

export default useTableSorter;
