import { generateFetchDataTypes, generateFetchDataActions } from 'ducks/utils';

export const types = {
  orgs: generateFetchDataTypes('UTTO/ADMIN/ORGS'),
  divs: generateFetchDataTypes('UTTO/ADMIN/DIVS'),
  teams: generateFetchDataTypes('UTTO/ADMIN/TEAMS'),
  members: generateFetchDataTypes('UTTO/ADMIN/MEMBERS'),
  managers: generateFetchDataTypes('UTTO/ADMIN/MANAGERS'),
  users: generateFetchDataTypes('UTTO/ADMIN/USERS'),
  newOrg: generateFetchDataTypes('UTTO/ADMIN/NEW_ORG'),
  newDiv: generateFetchDataTypes('UTTO/ADMIN/NEW_DIV'),
  newTeam: generateFetchDataTypes('UTTO/ADMIN/NEW_TEAM'),
  newUser: generateFetchDataTypes('UTTO/ADMIN/NEW_USER'),
  editUser: generateFetchDataTypes('UTTO/ADMIN/EDIT_USER'),
  userAccountSuspension: generateFetchDataTypes('UTTO/ADMIN/SUSPEND_USER'),
  userVrs: generateFetchDataTypes('UTTO/ADMIN/VRS'),
  newRole: generateFetchDataTypes('UTTO/ADMIN/NEW_ROLE'),
  deleteRole: generateFetchDataTypes('UTTO/ADMIN/DELETE_ROLE'),
  newSupportForm: generateFetchDataTypes('UTTO/ADMIN/NEW_SUPPORT_FORM'),
  utilityTypes: generateFetchDataTypes('UTTO/ADMIN/UTILITY_TYPES'),
  newUtilityTypeAllocation: generateFetchDataTypes(
    'UTTO/ADMIN/NEW_UTILITY_TYPE_ALLOCATION'
  ),
  unprocessedUploads: generateFetchDataTypes('UTTO/ADMIN/UNPROCESSED_UPLOADS'),
  refreshUnprocessed: generateFetchDataTypes('UTTO/ADMIN/REFRESH_UNPROCESSED'),
  reprocessUpload: generateFetchDataTypes('UTTO/ADMIN/REPROCESS_UPLOAD'),
  deleteUpload: generateFetchDataTypes('UTTO/ADMIN/DELETE_UPLOAD'),
  uploads: generateFetchDataTypes('UTTO/ADMIN/UPLOADS'),
  singleUpload: generateFetchDataTypes('UTTO/ADMIN/SINGLE_UPLOAD')
};

// retrieving lists of entities from server
export const orgsActions = generateFetchDataActions(types.orgs);
export const divsActions = generateFetchDataActions(types.divs);
export const teamsActions = generateFetchDataActions(types.teams);
export const membersActions = generateFetchDataActions(types.members);
export const managersActions = generateFetchDataActions(types.managers);
export const usersActions = generateFetchDataActions(types.users);

// posting new entities
export const newOrgActions = generateFetchDataActions(types.newOrg);
export const newDivActions = generateFetchDataActions(types.newDiv);
export const newTeamActions = generateFetchDataActions(types.newTeam);
export const newUserActions = generateFetchDataActions(types.newUser);
export const newRoleActions = generateFetchDataActions(types.newRole);
export const newSupportFormActions = generateFetchDataActions(
  types.newSupportForm
);

// updating entities
export const editUserActions = generateFetchDataActions(types.editUser);
export const userAccountSuspensionActions = generateFetchDataActions(
  types.userAccountSuspension
);
export const userVrsActions = generateFetchDataActions(types.userVrs);
export const deleteRoleActions = generateFetchDataActions(types.deleteRole);

// utility type getting/setting TODO: this isn't really 'admin'...
export const utilityTypeActions = generateFetchDataActions(types.utilityTypes);
export const newUtilityTypeActions = generateFetchDataActions(
  types.newUtilityTypeAllocation
);

// system stuff
export const unprocessedUploadActions = generateFetchDataActions(
  types.unprocessedUploads
);

export const refreshUnprocessedActions = generateFetchDataActions(
  types.refreshUnprocessed
);

export const reprocessUploadActions = generateFetchDataActions(
  types.reprocessUpload
);
export const deleteUploadActions = generateFetchDataActions(types.deleteUpload);
export const uploadsActions = generateFetchDataActions(types.uploads);
export const singleUploadActions = generateFetchDataActions(types.singleUpload);
