import {
  all,
  call,
  select,
  put,
  debounce,
  takeEvery
} from 'redux-saga/effects';
import {
  types as teamTypes,
  siteReportActions,
  siteReportAlertsActions,
  alertResolveActions,
  scoresAlertsActions
} from 'ducks/team/actions';
import { getOrgDivTeam, getOrgDiv } from 'ducks/auth/selectors';
import { openSnackbar } from 'ducks/auth/actions';
import { fetchDataWorkerSaga, postDataWorkerSaga } from './util';
import {
  fetchTeamMembers,
  fetchTeamScoresIndividual,
  fetchTeamSiteReport,
  fetchSiteReportAlerts,
  fetchActivityScores,
  fetchActivityHistogram,
  fetchTeamAlerts,
  suppressAlert,
  fetchTeamAlertsConfig,
  saveTeamAlertsConfig,
  fetchTeamsAlertsScores,
  fetchSessionSearch,
  fetchLatestSearch
} from 'services/api';
import { overallScore } from 'utils/overallScore';
import moment from 'moment';

function* getTeamLocators(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(teamTypes.locators, fetchTeamMembers, {
      org,
      div,
      team,
      ...action.payload
    });
  } catch (err) {
    console.log(err);
  }
}

function* getIndividualScores(action) {
  try {
    let { org, div, ...params } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      teamTypes.scoresHistory,
      fetchTeamScoresIndividual,
      {
        org,
        div,
        ...params,
        ...action.payload
      },
      {},
      res => {
        res.user_scores.forEach(user_scores => {
          user_scores.values.forEach(values => {
            values.metrics.overallScore = overallScore(values.metrics, {
              has_underscores: false
            });
          });
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getActivityHistogram(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      teamTypes.teamActivityHistograms,
      fetchActivityHistogram,
      { ...action.payload, orgId: org, teamId: team, divId: div },
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

function* getSiteReports(action) {
  //console.log(JSON.stringify(action.payload));
  const count = parseInt(action.payload.pageSize);
  const offset = parseInt(action.payload.page) * count;
  try {
    let _filters = [];
    _filters = action.payload.search && JSON.parse(action.payload.search)?.map(f => f.filter);
    yield* fetchDataWorkerSaga(
      teamTypes.siteReports,
      fetchSessionSearch,
      {
        after: moment(action.payload.from).unix(),
        before: moment(action.payload.to).unix(),
        filters: _filters,
        count: count,
        offset: offset,
        sort_key: action.payload.orderBy,
        sort_direction: action.payload.orderDirection
      },
      {
        //...action.payload,
      },
      res => ({
        ...res,
        body: res.body.map(item => ({
          ...item,
          id: item.pgid,
          lat: item.geo_point.lat,
          lon: item.geo_point.lon,
          overall: overallScore(item)
        }))
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getActiveUsers(action) {
  //console.log(JSON.stringify(action.payload));
  const count = parseInt(action.payload.pageSize);
  const offset = parseInt(action.payload.page) * count;
  try {
    let _filters = [];
    _filters = action.payload.search && JSON.parse(action.payload.search)?.filter((f) => f.filter.includes('team|') || f.filter.includes('user|')).map(f => f.filter);
    yield* fetchDataWorkerSaga(
      teamTypes.activeUsers,
      fetchLatestSearch,
      {
        after: moment(action.payload.from).unix(),
        before: moment(action.payload.to).unix(),
        filters: _filters,
        count: count,
        offset: offset,
        sort_key: action.payload.orderBy,
        sort_direction: action.payload.orderDirection
      },
      {
        //...action.payload,
      },
      res => ({
        ...res,
        body: res.body.map(item => ({
          ...item,
          id: item.pgid,
          lat: item.geo_point.lat,
          lon: item.geo_point.lon,
          overall: overallScore(item)
        }))
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getSiteReport(action) {
  try {
    const { sessionId, ...payloadRest } = action?.payload;
    // if we don't have sessionId, clear state and return without fetching
    if (!sessionId) {
      yield put(siteReportActions.clear());
      return;
    }

    const { org, team, div } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      teamTypes.siteReport,
      fetchTeamSiteReport,
      {
        org,
        team,
        sessionId,
        div
      },
      {
        ...payloadRest,
        projection:
          'utilityType,id,userId,teamId,locatorDetails,latlon,startTime,duration,geo,numPoints,numAssets,modalFrequency,minCurrent,location,metrics,distanceWalked,spanDistance,walkingSpeed,activity,overall'
      },
      res => ({
        ...res,
        overall: overallScore(res)
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getSiteReportAlerts(action) {
  try {
    // if we don't have sessionId, clear state and return without fetching
    if (!action?.payload?.sessionId) {
      yield put(siteReportAlertsActions.clear());
      return;
    }

    const { org, div, team } = yield select(getOrgDivTeam);

    yield* fetchDataWorkerSaga(
      teamTypes.siteReportAlerts,
      fetchSiteReportAlerts,
      null,
      {
        div,
        owner: team && `${org}/teams/${team}`,
        ...action.payload
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getTeamActivity(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      teamTypes.activityHistory,
      fetchActivityScores,
      { ...action.payload, org, div, team },
      null
    );
  } catch (err) {
    console.log(err);
  }
}

function* getTeamAlerts(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(teamTypes.alerts, fetchTeamAlerts, null, {
      div,
      owner: `${org}/teams/${team}`,
      ...action.payload
    });
  } catch (err) {
    console.log(err);
  }
}

function* resolveTeamAlert(action) {
  const { id, issueType, comment, resolution } = action.payload;
  try {
    const { org, team } = yield select(getOrgDivTeam);
    const { id: responseId, resolved } = yield call(
      suppressAlert,
      { id, owner: `${org}/teams/${team}` },
      { issueType, comment, resolution }
    );

    if (responseId) {
      yield put(alertResolveActions.success({ id: responseId, resolved }));
      if (resolved) {
        yield put(openSnackbar('success', 'Alert resolved successfully'));
      } else {
        yield put(openSnackbar('warning', 'Alert saved successfully'));
      }
    } else {
      yield put(alertResolveActions.failure({ id }));
      yield put(openSnackbar('error', 'Error resolving alert'));
    }
  } catch (err) {
    console.log(err);
    yield put(alertResolveActions.failure({ id }));
    yield put(openSnackbar('error', 'Error resolving alert'));
  }
}

function* getTeamAlertsSettings(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      teamTypes.alertsSettings,
      fetchTeamAlertsConfig,
      { org, div, team },
      null,
      res => res.reduce((d,x) => (d[x.id]=x,d), {}),
    );
  } catch (err) {
    console.log(err);
  }
}

function* saveAlertsSettings(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* postDataWorkerSaga(
      teamTypes.saveAlertsSettings,
      saveTeamAlertsConfig,
      { org, div, team },
      action.payload
    );
  } catch (err) {
    console.log(err);
  }
}

function* getAlertsScores(action) {
  try {
    const { org, div } = yield select(getOrgDiv);
    if (!div) {
      throw new Error('Division is not available!');
    }
    yield* fetchDataWorkerSaga(
      teamTypes.scoresAlerts,
      fetchTeamsAlertsScores,
      {
        org,
        div,
        ...action.payload
      },
      {}
    );
  } catch (err) {
    yield put(scoresAlertsActions.failure());
  }
}

export default function* sagas() {
  yield all([
    yield debounce(50, teamTypes.locators.REQUEST, getTeamLocators),
    yield debounce(50, teamTypes.scoresHistory.REQUEST, getIndividualScores),
    yield debounce(50, teamTypes.siteReports.REQUEST, getSiteReports),
    yield debounce(50, teamTypes.activeUsers.REQUEST, getActiveUsers),
    yield debounce(50, teamTypes.siteReport.REQUEST, getSiteReport),
    yield debounce(50, teamTypes.siteReportAlerts.REQUEST, getSiteReportAlerts),
    yield debounce(50, teamTypes.activityHistory.REQUEST, getTeamActivity),
    yield debounce(
      50,
      teamTypes.teamActivityHistograms.REQUEST,
      getActivityHistogram
    ),
    yield debounce(50, teamTypes.alerts.REQUEST, getTeamAlerts),
    yield takeEvery(teamTypes.alertResolve.REQUEST, resolveTeamAlert),
    yield debounce(50, teamTypes.alertsSettings.REQUEST, getTeamAlertsSettings),
    yield takeEvery(teamTypes.saveAlertsSettings.REQUEST, saveAlertsSettings),
    yield debounce(50, teamTypes.scoresAlerts.REQUEST, getAlertsScores)
  ]);
}
