import useQueryString from './useQueryString';
import { useMemo } from 'react';

const useAlertResolutionParams = () => {
  const [alertResolution] = useQueryString('resolution', -1);

  const resolution = useMemo(() => {
    if (Number(alertResolution) !== -1) {
      return {
        resolved: Boolean(Number(alertResolution))
      };
    }
    return {};
  }, [alertResolution]);

  return [resolution];
};

export default useAlertResolutionParams;
