import { combineReducers } from 'redux';
import auth from './auth';
import team from './team';
import technician from './technician';
import admin from './admin';
import area from './area';
import { RESET_STORE } from './actions';

const reducers = combineReducers({
  auth,
  team,
  technician,
  admin,
  area
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return reducers(state, action)
}

export default rootReducer;
