import React from 'react';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

const TickCrossRenderer = val => {
  if (val === undefined || val === null)
    return <Clear style={{ fill: 'silver' }} />;
  else {
    return val ? (
      <Done style={{ fill: 'green' }} />
    ) : (
      <Clear style={{ fill: 'red' }} />
    );
  }
};

export default TickCrossRenderer;
