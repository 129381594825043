import querystring from 'query-string';
import tokenStorage from 'services/tokenStorage';
import baseLogger from 'services/logger';
import history from 'services/history';
import { API_BASE_URL, PERMISSIONS } from 'config';

// alias the URI encoding function for readability further below
const enc = encodeURIComponent;
// create a child logger with context to differentiate API logs
const logger = baseLogger.child({ context: 'api' });

const handleErrors = async response => {
  switch (response.status) {
    case 401: {
      tokenStorage.clear();
      history.push('/login', {
        error: 'Session expired. Please sign in again.'
      });
      let message;
      try {
        const body = await response.json();
        message = body?.message;
      } catch {
        message = 'Unauthorized';
      }
      throw { message: message, silent: true };
    }
    default:
      break;
  }
};

const createRequest = (endpoint, method, payload) => {
  const token = tokenStorage.get()?.token;
  let fullUrl = API_BASE_URL + endpoint;

  let headers = {
    'Content-Type': 'application/json'
  };
  if (token) headers['X-Authorization'] = `Bearer ${token}`;

  let config = {
    method,
    headers: new Headers(headers)
  };

  // If there's a ? int the endpoint use & to separate parameters
  let seperator = '?';
  if (endpoint.indexOf('?') >= 0) seperator = '&';

  // put payload in query string for GET requests and body for others
  if (payload) {
    if (method === 'GET') {
      fullUrl += seperator + querystring.stringify(payload);
    } else {
      config.body = JSON.stringify(payload);
    }
  }
  //console.log('Url:' + fullUrl);
  let request = new Request(fullUrl, config);

  //console.log('request: ', request);
  return request;
};

export const callApiCore = async (
  endpoint,
  method = 'GET',
  payload = null,
  requiredHeaders = []
) => {
  try {
    const response = await fetch(createRequest(endpoint, method, payload));
    await handleErrors(response);
    let headers = {};
    if (requiredHeaders && requiredHeaders.length > 0) {
      requiredHeaders &&
        requiredHeaders.forEach(h => {
          headers[h] = response.headers.get(h);
        });
    }
    let body;
    try {
      body = await response.json();
      //console.log('callApiCore: ', endpoint);
      //console.log('callApiCore body: ', body);
    } catch (err) {
      body = {};
    }
    if (!response.ok)
      throw body;
    return { body, headers };
  } catch (error) {
    if (!error.silent) {
      logger.error('fetch request error', error, { endpoint, method });
    }
    throw error; // rethrow so handlers above the chain can still recover
  }
};

export const callApi = async (
  endpoint,
  method = 'GET',
  payload = null,
  demoDataFuncName = null,
  requiredHeaders = []
) => {
    const res = await callApiCore(endpoint, method, payload, requiredHeaders);
    return requiredHeaders?.length ? res : res.body;
};

export const login = (email, password) => {
  const check_privileges = [
    PERMISSIONS.VIEW_FIELD_TECH_UI,
    PERMISSIONS.VIEW_TEAM_UI,
    PERMISSIONS.VIEW_ADMIN_UI,
    PERMISSIONS.VIEW_SINGLE_USER_DEMO_UI,
    PERMISSIONS.VIEW_SYS_ADMIN_UI,
    PERMISSIONS.VIEW_DIVISION_UI,
    PERMISSIONS.VIEW_UTILITY_UI
  ];

  return callApi('auth/login/', 'POST', { email, password, check_privileges });
};

// NOTE: using fetch with empty catch block as we don't care about response or errors
export const writeLog = payload =>
  fetch(createRequest('ui-logging/log/', 'POST', payload)).catch(() => {});

/**
 *
 * @param {object} args
 * example: {
 *   from: '2018-01-04T17:09:51.505Z' - REQUIRED
 *   to: '2018-01-04T17:09:51.505Z' - REQUIRED
 *   per: 'month' - or 'day', 'week' - DEFAULTS TO MONTH
 *   orgId: 'UttoTestOrg' \
 *   teamId: 'team1'      - provide these to get team scores
 *   userId: 'UttoTestOrg/users/fabio@utto.com' - provide this (WITHOUT org and team) to get locator's score
 * }
 *
 */
export const fetchActivityScores = (args = {}, payload = null) => {
  return callApi(
    'performance/activity/scores/historic/',
    'GET',
    args,
    'activityScores'
  );
};

/**
 *
 * @param {object} args
 * example:
 *   teamId: 'team1'      - provide these to get team scores
 *   userId: 'UttoTestOrg/users/fabio@utto.com' - provide this (WITHOUT org and team) to get locator's score
 */
export const fetchActivityHistogram = (args = {}) => {
  return callApi('activity/histograms/', 'GET', args, 'activityHistogram');
};

export const fetchTeamMembers = async (args = {}) => {
  const { org = '', div = '', team = '' } = args;
  let parts = [`${enc(org)}/divisions`];
  if (div) {
    parts.push(`${enc(div)}/teams`);
  }
  if (team) {
    parts.push(`${enc(team)}/members/`);
  }
  const data = await callApi(
    `entities/organisations/${parts.join('/')}`,
    'GET',
    team ? null : { include: 'members' },
    'teamMembers'
  );

  if (!team) {
    return data.reduce((members, team) => {
      return members.concat(team.members);
    }, []);
  }
  return data;
};

export const fetchTeamAlerts = (args = {}, payload = null) =>
  callApi('alerts/all/', 'GET', payload, 'teamAlerts', ['X-Total-Count']);

export const suppressAlert = (args = {}, payload = null) => {
  const { id = '', owner = '' } = args;
  return callApi(
    `alerts/id/${enc(id)}/suppress/`,
    'POST',
    { ...payload, owner },
    'empty'
  );
};

export const fetchTechnicianScoresHistory = (args = {}, payload = null) =>
  callApi('performance/scores/historic/', 'GET', payload, 'scoresHistoric');

export const fetchTeamScoresIndividual = (args = null) =>
  callApi('performance/scores/individual/', 'GET', args, 'scoresIndividual');

export const fetchTeamsAlertsScores = (args = {}) => {
  return callApi(`alerts/statistic`, 'GET', args, 'alertsScores');
};

export const fetchTeamScoresAverage = (args = {}) =>
  callApi('performance/scores/average/', 'GET', args, 'scoresAverage');

export const fetchSessionsFilters = (args = {}, payload = null) => {
  const response = callApi('sessions/filters', 'GET', args, '', [
    'X-Total-Count'
  ]);

  return response;
};

export const fetchSessionSearch = (args = {}, payload = null) => {
  //console.log(JSON.stringify(args));
  return callApi(
    'sessions/search',
    'GET',
    {
      filters: args.filters,
      after: args.after,
      before: args.before,
      count: args.count,
      offset: args.offset,
      sort_key: args.sort_key,
      sort_direction: args.sort_direction
    },
    'teamSiteReports',
    ['X-Total-Count']
  );
};

export const fetchLatestSearch = (args = {}, payload = null) => {
  //console.log(JSON.stringify(args));
  return callApi(
    'sessions/latest',
    'GET',
    {
      filters: args.filters,
      after: args.after,
      before: args.before,
      count: args.count,
      offset: args.offset,
      sort_key: args.sort_key,
      sort_direction: args.sort_direction
    },
    'teamSiteReports',
    ['X-Total-Count']
  );
};

export const fetchStudiesSearch = (args = {}, payload = null) => {
  //console.log('fetchStudiesSearch: ',args);
  return callApi(
    'studies/pspd',
    'GET',
    {
      filters: args.filters,
      after: args.after,
      before: args.before,
      count: args.count,
      offset: args.offset,
      sort_key: args.sort_key,
      sort_direction: args.sort_direction
    },
    'areaStudies',
    ['X-Total-Count']
  );
};

export const fetchDamagesSearch = (args = {}, payload = null) => {
  //console.log('fetchDamagesSearch: ',args);
  return callApi(
    'studies/psbq',
    'GET',
    {
      filters: args.filters,
      after: args.after,
      before: args.before,
      count: args.count,
      offset: args.offset,
      sort_key: args.sort_key,
      sort_direction: args.sort_direction
    },
    'areaStudies',
    ['X-Total-Count']
  );
};

export const fetchSiteReport = async (args = {}, payload = null) => {
  const { sessionId = '' } = args;
  if (payload.userId) {
    const res = await callApi(
      `session/${enc(sessionId)}`,
      'GET',
      payload,
      'singleSiteReport'
    );
    return res;
  }
};

export const fetchTeamSiteReport = async (args = {}, payload = null) => {
  const { org = '', team = '', sessionId = '', div = '' } = args;
  const groupId = team && `${org}/teams/${team}`;
  const res = await callApi(
    `session/${enc(sessionId)}`,
    'GET',
    { ...payload, groupId, div },
    'singleTeamSiteReport'
  );
  return res;
};

export const fetchSiteReportAlerts = async (args = {}, payload = null) => {
  const { sessionId = '', owner = '', div = '' } = payload;
  const res = await callApi(
    'alerts/all/',
    'GET',
    {
      contentType: 'SITE_REPORT',
      suppressed: true,
      div,
      owner,
      content: `sessionId:${sessionId}`
    },
    'siteReportAlerts'
  );
  return res;
};

// ADMIN - note that the API spells organisation with an S
export const postOrg = payload =>
  callApi('entities/organisations/', 'POST', payload, 'empty');

export const postDiv = ({ org = '', name = '' }) =>
  callApi(
    `entities/organisations/${enc(org)}/divisions/`,
    'POST',
    { name },
    'empty'
  );

export const postTeam = ({ organization = '', division = '', name = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/divisions/${enc(
      division
    )}/teams/`,
    'POST',
    { name },
    'empty'
  );

export const postRole = ({
  organization = '',
  division = '',
  email = '',
  role = '',
  team = ''
}) => {
  const params = {
    role_type: `global.${role}`,
    group: team
      ? `${organization}/teams/${team}`
      : `${organization}/divs/${division}`,
    group_type: team ? 'team' : 'division'
  };
  return callApi(
    `entities/organisations/${enc(organization)}/users/${enc(email)}/roles/`,
    'POST',
    params,
    'empty'
  );
};

export const deleteRole = ({
  organization = '',
  division = '',
  email = '',
  role = '',
  team = ''
}) => {
  const params = {
    role_type: `global.${role}`,
    group: team
      ? `${organization}/teams/${team}`
      : `${organization}/divs/${division}`,
    group_type: team ? 'team' : 'division'
  };
  return callApi(
    `entities/organisations/${enc(organization)}/users/${enc(email)}/roles/`,
    'DELETE',
    params,
    'empty'
  );
};

export const postUser = payload => {
  const { org = '' } = payload;
  return callApi(
    `entities/organisations/${enc(org)}/users/`,
    'POST',
    payload,
    'empty'
  );
};

export const fetchOrgs = () =>
  callApi('entities/organisations/', 'GET', null, 'empty');

export const fetchDivs = ({ organization = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/divisions/`,
    'GET',
    null,
    'empty'
  );

export const fetchTeams = ({ organization = '', division = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/divisions/${enc(
      division
    )}/teams/`,
    'GET',
    null,
    'empty'
  );

export const fetchManagers = ({ organization = '', division = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/divisions/${enc(
      division
    )}/managers/`,
    'GET',
    null,
    'empty'
  );

export const fetchMembers = ({ organization = '', division = '', team = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/divisions/${enc(
      division
    )}/teams/${enc(team)}/members/`,
    'GET',
    null,
    'empty'
  );

export const fetchUsers = ({ organization = '' }) =>
  callApi(
    `entities/organisations/${enc(organization)}/users/`,
    'GET',
    null,
    'empty'
  );

export const editUser = (args = {}, params = {}) => {
  const { org = '', email = '' } = args;

  return callApi(
    `entities/organisations/${enc(org)}/users/${enc(email)}/`,
    'PUT',
    params,
    'empty'
  );
};

export const editUserAccountSuspension = (args = {}) => {
  // action = 'enable' || 'disable'
  const { org = '', email = '', action = '' } = args;
  return callApi(
    `entities/organisations/${enc(org)}/users/${enc(email)}/enablement/${enc(
      action
    )}/`,
    'PUT',
    null,
    'empty'
  );
};

export const editUserVrs = (args = {}) => {
  // action = 'enable' || 'disable'
  const { org = '', email = '', action = '' } = args;
  return callApi(
    `entities/organisations/${enc(org)}/users/${enc(email)}/vrs/${enc(
      action
    )}/`,
    'PUT',
    null,
    'empty'
  );
};

export const sendPasswordResetEmail = payload =>
  callApi('auth/password/send_reset/', 'POST', payload);

export const resetPassword = payload =>
  callApi('auth/password/reset_password/', 'POST', payload);

export const changePassword = payload =>
  callApi('auth/password/change/', 'POST', payload);

export const postSupportForm = payload =>
  callApi('help/message/', 'POST', payload);

export const updateUserDetails = (args = {}, payload = null) => {
  const { org = '', email = '' } = args;
  return callApi(
    `entities/organisations/${enc(org)}/users/${enc(email)}/`,
    'PUT',
    payload
  );
};

export const createSingleUserAccount = payload =>
  callApi('entities/single_user_org/', 'POST', payload);

export const fetchTeamAlertsConfig = (args = {}) => {
  const { org, team, div } = args;
  const accId = team ? `${org}/teams/${team}` : `${org}/divs/${div}`;
  return callApi(
    `config/alerts?ac_id=${enc(accId)}`,
    'GET',
    null,
    'alertsConfig'
  );
};

export const saveTeamAlertsConfig = (args = {}, payload) => {
  const { org, team, div } = args;
  const accId = team ? `${org}/teams/${team}` : `${org}/divs/${div}`;
  return callApi(
    `config/alerts?ac_id=${enc(accId)}`,
    'PUT',
    payload,
    'empty'
  );
};

export const fetchUtilityTypes = () =>
  callApi('utility-types/', 'GET', null, 'utilityTypes');

export const putUtilityType = (sessionId = '', payload) =>
  callApi(
    `locate-sessions/${enc(sessionId)}/utility-type/`,
    'PUT',
    payload,
    'empty'
  );

export const fetchUnprocessedUploads = () => callApi('uploads/stuck/');

export const reprocessUpload = id =>
  callApi(`uploads/uploads/${enc(id)}/reprocess/`, 'PUT');

export const annihilateUpload = id =>
  callApi(`uploads/uploads/${enc(id)}/`, 'DELETE');

export const fetchUploads = (args = {}) =>
  callApi('uploads/uploads/', 'GET', args);

export const fetchSingleUpload = (id, params) =>
  callApi(`uploads/uploads/${enc(id)}/`, 'GET', params);

export const triggerRefresh = () => callApi('uploads/stuck/refresh/', 'PUT');

export const fetchExport = (sessionId, format) => {
  return callApi(
    `session/${enc(sessionId)}?format=${format}&dl=1`,
    'GET',
    null,
    'empty',
    ['ExportURI']
  );
};

export const deleteSiteReport = (pgid, payload={}) => {
  console.log("deleteSiteReport: "+pgid+", "+JSON.stringify(payload))
  return callApi(
    `session/${enc(pgid)}/delete`,
    'POST',
    payload
  );
};
