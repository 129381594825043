import React, { useState, useMemo, useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { VIEW_TYPES, PERMISSIONS } from 'config';
import { useRouter, useReduxSelector, useReduxAction } from 'hooks';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import history from 'services/history';
import {
  getIsLoggedIn,
  getOverwritePermissions,
  getPermissionSubjects
} from 'ducks/auth/selectors';
import {
  logout,
  changeCurrentTeam,
  changeCurrentDivision
} from 'ducks/auth/actions';

export const useDrawerToggle = () => {
  const stored = localStorage.getItem('cloud.drawerOpen') || '1';
  const [isOpen, setIsOpen] = useState(stored === '1');
  const onToggleDrawer = useCallback(() => {
    localStorage.setItem('cloud.drawerOpen', isOpen ? '0' : '1');
    setIsOpen(!isOpen)
  }, [ isOpen, setIsOpen]);

  return [isOpen, onToggleDrawer];
};

const SUPPORT_NAVIGATION_LINK = {
  label: 'Support',
  icon: <ContactSupportIcon />,
  onClick: () => {
    window.location.href = 'mailto:support@utto.com';
  }
};

export const useNavigationButtons = pages => {
  const { history, location } = useRouter();

  return useMemo(
    () =>
      // map the list of pages into the correct format for the sidebar
      [
        ...pages.map(page => ({
          label: page.label,
          icon: page.icon,
          active: Boolean(matchPath(location.pathname, { path: page.href })),
          onClick: page.disabled
            ? undefined
            : () =>
                history.push({
                  pathname: page.href,
                  search: page.queryTransformer
                    ? page.queryTransformer(location.search)
                    : location.search
                })
        })),
        SUPPORT_NAVIGATION_LINK
      ],
    [pages, location.pathname, history, location.search]
  );
};

export const useAccountMenuButtons = () => {
  const onLogout = useReduxAction(logout);
  const isLoggedIn = useReduxSelector(getIsLoggedIn);

  const buttons = useMemo(
    () => [
      {
        label: 'Home',
        onClick: () => history.push('/')
      },
      {
        label: 'Account details',
        onClick: () => history.push('/account/details')
      },
      {
        label: 'Help and support',
        onClick: () => history.push('/support')
      },
      {
        label: 'FAQs',
        onClick: () => window.open('https://utto.freshdesk.com/support/solutions', '_blank')
      },
      { label: 'Sign out', onClick: onLogout }
    ],
    [onLogout]
  );

  return isLoggedIn ? buttons : [];
};

export const useChangeViewButtons = () => {
  const permissions = useReduxSelector(getOverwritePermissions);
  const permissionSubjects = useReduxSelector(getPermissionSubjects);
  const changeTeam = useReduxAction(changeCurrentTeam);
  const changeDivision = useReduxAction(changeCurrentDivision);

  const buttons = useMemo(() => {
    let buttons = [];

    const filteredViews = VIEW_TYPES.filter(view =>
      view.permissions.some(p => permissions.includes(p))
    );

    // generate buttons for each view
    filteredViews.forEach(view => {
      if (view.allowMultiple) {
        // for views that support mutli-team, we must find every team
        // of every permission that allows access to that page
        view.permissions.forEach(perm => {
          if (
            (perm === PERMISSIONS.VIEW_TEAM_UI ||
              perm === PERMISSIONS.VIEW_FIELD_TECH_UI) &&
            permissionSubjects[perm]
          ) {
            permissionSubjects[perm].forEach(team => {
              const teamId = team.split('/')?.[2];
              buttons.push({
                label: `${view.label} - ${teamId}`,
                onClick: () => {
                  changeTeam(teamId, 'division1');
                  history.push(view.href);
                }
              });
            });
          } else if (
            (perm === PERMISSIONS.VIEW_DIVISION_UI ||
              perm === PERMISSIONS.VIEW_UTILITY_UI) &&
            permissionSubjects[perm]
          ) {
            permissionSubjects[perm].forEach(team => {
              const divisionId = team.split('/')?.[2];
              buttons.push({
                label: `${view.label} - ${divisionId}`,
                onClick: () => {
                  changeDivision(divisionId);
                  history.push(view.href);
                }
              });
            });
          }
        });
      } else {
        buttons.push({
          label: view.label,
          onClick: () => history.push(view.href)
        });
      }
    });

    return buttons;
  }, [changeTeam, changeDivision, permissionSubjects, permissions]);

  return buttons;
};
