import { ENABLED_METRICS } from 'config';

export const getScoreKeys = object =>
  Object.keys(object)
    .filter(item => item.indexOf('score') !== -1)
    .filter(item => ENABLED_METRICS.includes(item.split('_')[0]));

const getScoreKeysNoUnderscores = object =>
  Object.keys(object).filter(item =>
    ENABLED_METRICS.includes(item.split('_')[0])
  );

export const overallScore = (object, config = {}) => {
  const { has_underscores = true } = config;
  let length = 0;
  let sum = 0;

  const scoreKeys = has_underscores
    ? getScoreKeys(object)
    : getScoreKeysNoUnderscores(object);

  // allow backend-generated overall score to supersede calculated
  if (scoreKeys.includes('overall_score')) {
    return object['overall_score']
  }

  scoreKeys.forEach(element => {
    if (object[element] !== null && object[element] !== undefined) {
      length++;
      sum = sum + object[element];
    }
  });
  return sum / length;
};
