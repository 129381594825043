import { getPermissions } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

const usePermissions = (permissions = []) => {
  const userPermissions = useReduxSelector(getPermissions);

  return permissions.map(permission => userPermissions.includes(permission));
};

export default usePermissions;
