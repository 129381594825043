import { useMemo } from 'react';

const useScoresFilter = (scores, locatorIds) =>
  useMemo(
    () =>
      scores && locatorIds?.length
        ? {
            ...scores,
            user_scores: scores.user_scores?.filter(locator =>
              locatorIds.includes(locator.userId)
            )
          }
        : scores,
    [locatorIds, scores]
  );

export default useScoresFilter;
