import React from 'react';
import SlimTemplate from 'pages/slimTemplate';
import { Paper, Typography } from '@material-ui/core';
import SupportForm from 'components/admin/forms/SupportForm';
import useStyles from './styles';

const Support = () => {
  const classes = useStyles();

  return (
    <SlimTemplate showMenu title="Support">
      <div className={classes.dialogContainer}>
        <Paper className={classes.form}>
          <Typography variant="h6" className={classes.formTitle}>
            Having trouble? Contact UTTO support
          </Typography>
          <SupportForm />
          <div className={classes.formTitle}>
            <Typography variant="body2">To contact us directly:</Typography>
            <Typography variant="body2">
              email: <a href="mailto:support@utto.com">support@utto.com</a>
            </Typography>
            <Typography variant="body2">
              tel: <a href="tel:+1-844-811-8886">(844)-811-UTTO</a>
            </Typography>
          </div>
        </Paper>
      </div>
    </SlimTemplate>
  );
};

export default Support;
