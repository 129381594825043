import { useMemo } from 'react';

import { formatDuration } from 'utils/formatters';

const useAlertsStatsAggregator = alertsStats =>
  useMemo(() => {
    return {
      averageResolutionTime: formatDuration(alertsStats?.averageResolutionTime)
    };
  }, [alertsStats]);

export default useAlertsStatsAggregator;
