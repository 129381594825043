import React, {
  useState,
  useReducer,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import history from 'services/history';
import validate from './validator';
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { newSupportFormActions } from 'ducks/admin/actions';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getNewSupportForm } from 'ducks/admin/selectors';
import { getUser } from 'ducks/auth/selectors';
import useStyles from './styles';

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.name]: action.value,
        errors: { ...state.errors, [action.error.name]: action.error.value }
      };
    case 'reset':
      return INIT_STATE;
    case 'initialise':
      return { ...action.payload };
    default:
      return state;
  }
};

const INIT_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  referred_from: '',
  errors: {}
};

const SupportForm = () => {
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const reduxDispatch = useReduxAction(newSupportFormActions.request);
  const response = useReduxSelector(getNewSupportForm);
  const user = useReduxSelector(getUser);

  const handleChange = useCallback(
    evt => {
      evt.preventDefault();
      const { name, value } = evt.target;
      const err = validate.single(name, value);
      const error = { name: name, value: err ? err[0] : null };
      dispatch({
        type: 'update',
        name,
        value,
        error
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      const { firstname = '', lastname = '', email = '', phone = '' } = user;
      const payload = { ...INIT_STATE, firstname, lastname, email, phone };
      dispatch({ type: 'initialise', payload });
    }
  }, [user]);

  useEffect(() => {
    const referred_from = history?.location?.state?.referred_from;
    handleChange({
      preventDefault: () => {},
      target: { name: 'referred_from', value: referred_from }
    });
  }, [handleChange]);

  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();
      const { errors, ...rest } = state;
      reduxDispatch(rest);
      dispatch({ type: 'reset' });
      setSent(true);
    },
    [reduxDispatch, dispatch, state]
  );

  const hasErrors = useMemo(
    () => Object.values(state.errors).some(val => val),
    [state]
  );

  const dismissConfirmationDialog = () => setSent(false);

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        <div className={classes.formFieldContainer}>
          <TextField
            required
            label="First Name"
            name="firstname"
            error={Boolean(state.errors.firstname)}
            helperText={state.errors.firstname}
            value={state.firstname}
            onChange={handleChange}
            className={classes.formFieldDouble}
          />
          <TextField
            required
            label="Last Name"
            name="lastname"
            error={Boolean(state.errors.lastname)}
            helperText={state.errors.lastname}
            value={state.lastname}
            onChange={handleChange}
            className={classes.formFieldDouble}
          />
        </div>
        <div className={classes.formFieldContainer}>
          <TextField
            label="Phone"
            error={Boolean(state.errors.phone)}
            helperText={state.errors.phone}
            value={state.phone}
            name="phone"
            onChange={handleChange}
            className={classes.formFieldDouble}
          />

          <TextField
            required
            label="Email"
            error={Boolean(state.errors.email)}
            helperText={state.errors.email || ' '}
            value={state.email}
            name="email"
            onChange={handleChange}
            className={classes.formFieldDouble}
          />
        </div>
        <TextField
          required
          multiline
          rows="4"
          variant="outlined"
          label="Please describe the issue"
          error={Boolean(state.errors.issueDescription)}
          helperText={state.errors.issueDescription || ' '}
          value={state.message}
          name="message"
          onChange={handleChange}
          className={classes.upperMargin}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          disabled={Boolean(hasErrors)}
        >
          {response.pending ? (
            <CircularProgress size={20} color="white" />
          ) : (
            'Submit'
          )}
        </Button>
        <Typography className={classes.errorText} variant="body2">
          {response?.error}
        </Typography>
      </form>
      <Dialog
        open={sent && response?.data}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Message sent!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            'Our customer service team will contact you ASAP. Your support
            ticket number is'
            <strong> {response?.data?.request_id?.toUpperCase()}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissConfirmationDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SupportForm;
