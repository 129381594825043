import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { types, loginActions } from 'ducks/auth/actions';
import {
  login,
  updateUserDetails,
  createSingleUserAccount
} from 'services/api';
import tokenStorage from 'services/tokenStorage';
import history from 'services/history';
import { getUser } from 'ducks/auth/selectors';
import { postDataWorkerSaga } from './util';
import { RESET_STORE } from 'ducks/actions';

function* handleLogin(action) {
  try {
    yield call(tokenStorage.clear);
    yield put({ type: RESET_STORE});

    const { email, password } = action.payload;
    const { token, user } = yield call(login, email, password);
    yield call(tokenStorage.set, token, user);
    yield put(loginActions.success({ token, user }));
    yield call(history.push, '/');
  } catch (error) {
    yield put(loginActions.failure(error.message));
  }
}

function* handleLogout() {
  yield call(tokenStorage.clear);
  yield call(history.push, '/');
}

function* changeDetails(action) {
  try {
    const { org, email } = yield select(getUser);
    const res = yield* postDataWorkerSaga(
      types.changeDetails,
      updateUserDetails,
      { org, email },
      action.payload
    );
    yield call(tokenStorage.update_user, res);
  } catch (err) {
    console.log(err);
  }
}

function* createAccount(action) {
  try {
    yield* postDataWorkerSaga(
      types.createAccount,
      createSingleUserAccount,
      action.payload
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* sagas() {
  yield all([
    yield takeEvery(types.login.REQUEST, handleLogin),
    yield takeEvery(types.LOGOUT, handleLogout),
    yield takeEvery(types.changeDetails.REQUEST, changeDetails),
    yield takeEvery(types.createAccount.REQUEST, createAccount)
  ]);
}
