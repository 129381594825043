import { useEffect } from 'react';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getOrgDiv, getPermissionSubjects } from 'ducks/auth/selectors';
import { changeCurrentDivision } from 'ducks/auth/actions';

const useFirstDivisionIfNotSet = permissions => {
  const { div } = useReduxSelector(getOrgDiv);
  const permissionSubjects = useReduxSelector(getPermissionSubjects);
  const changeDivision = useReduxAction(changeCurrentDivision);

  useEffect(() => {
    if (!div) {
      const potentialSubjects = permissions
        .map(perm => permissionSubjects?.[perm])
        .reduce((a, b) => a.concat(b));
      const firstSubjectShortID = potentialSubjects[0]?.split('/')?.[2];

      changeDivision(firstSubjectShortID);
    }
  }, [div, changeDivision, permissionSubjects, permissions]);
};

export default useFirstDivisionIfNotSet;
