import React from 'react';
import { createTheme } from '@material-ui/core';
import DirectionsWalkRounded from '@material-ui/icons/DirectionsWalkRounded';
import Timer from '@material-ui/icons/Timer';
import TimerOff from '@material-ui/icons/TimerOff';
import TimerTimelapse from '@material-ui/icons/Timelapse';
import LinearScale from '@material-ui/icons/LinearScale';
import Flag from '@material-ui/icons/Flag';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Brightness1 from '@material-ui/icons/Brightness1';
import ThumbDown from '@material-ui/icons/ThumbDown';
import Refresh from '@material-ui/icons/Refresh';
import Note from '@material-ui/icons/Note';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  red,
  yellow,
  orange,
  blue,
  green,
  purple,
  blueGrey
} from '@material-ui/core/colors';
import { ReactComponent as Logo } from './uttoLogo.svg';

// TODO: replace the arbitrary hex colors with ones from the Material-UI palette
//       (https://material-ui.com/customization/color/#color-palette)
const theme = createTheme({
  palette: {
    primary: {
      main: '#100D72' // logo blue color
    },
    secondary: {
      main: '#4DAC26' // logo green color
    },
    charts: [
      '#100d72',
      '#4dac26',
      '#f07314',
      '#c51c16',
      '#50d8d3',
      '#f500e1',
      '#9419e1',
      '#cae119',
      '#37ff1f',
      '#5a3316',
      '#e33939',
      '#327770',
      '#ffe700'
    ],
    radarCharts: {
      primary: {
        stroke: '#100d72',
        fill: '#272489'
      },
      secondary: {
        stroke: '#086700',
        fill: '#36950f'
      }
    }
  },
  utilityColors: {
    Grey: blueGrey[600],
    Yellow: yellow[600],
    Red: red[600],
    Orange: orange[700],
    Blue: blue[500],
    Purple: purple[500],
    Green: green[500]
  },
  stats: {
    riskHigh: {
      color: red[600],
    },
    riskMedium: {
      color: orange[700],
    },
    riskLow: {
      color: green[500],
    },
    riskNA: {
      color: blueGrey[200],
    },
    riskIndicator: {
      icon: BarChartIcon,
    },
    assetsUploaded: {
      color: '#9419e1',
      icon: AddPhotoAlternate
    },
    averageUttoScore: {
      icon: props => <Logo width="1em" height="1em" {...props} />,
      color: '#100D72' // same as palette.primary.main
    },
    averageDistanceWalked: {
      icon: DirectionsWalkRounded,
      color: '#5a3316'
    },
    averageLocateDistance: {
      icon: DirectionsWalkRounded,
      color: '#16495a'
    },
    averageTimeTaken: {
      icon: Timer,
      color: '#f07314'
    },
    averageResolutionTime: {
      icon: NotificationsIcon,
      color: '#4dac26'
    },
    averageSessionsPerLocator: {
      icon: LinearScale,
      color: '#4dac26'
    },
    distanceWalked: {
      icon: DirectionsWalkRounded,
      color: '#5a3316'
    },
    locateDistance: {
      icon: DirectionsWalkRounded,
      color: '#16495a'
    },
    distanceBetweenPoints: {
      icon: LinearScale,
      color: '#4dac26'
    },
    pointsTaken: {
      icon: Flag,
      color: '#f500e1'
    },
    sessionTime: {
      color: '#f07314',
      icon: Timer
    },
    sessionEnd: {
      color: '#f07314',
      icon: TimerOff
    },
    sessionDuration: {
      color: '#f07314',
      icon: Timer
    },
    sessionDistance: {
      color: '#5a3316',
      icon: DirectionsWalkRounded
    },
    sessionsWithAlerts: {
      color: '#c51c16',
      icon: WarningIcon
    },
    stuckUploads: {
      color: '#f07314',
      icon: ThumbDown
    },
    totalTimeTaken: {
      icon: Timer,
      color: '#f07314'
    },
    totalNumberOfSessions: {
      icon: LinearScale,
      color: '#4dac26'
    },
    timeBetweenPoints: {
      icon: Timer,
      color: '#f07314'
    },
    timeOnSite: {
      icon: Timer,
      color: '#f07314'
    },
    timeAwayFromUtility: {
      icon: TimerTimelapse,
      color: '#9419e1'
    },
    ticketNo: {
      color: '#bcbcbc',
      icon: Note
    },
    utilityType: {
      color: '#4054b2',
      icon: Brightness1
    },
    refresh: {
      color: '#4054b2',
      icon: Refresh
    }
  },
  variables: {
    gridGap: '1rem',
    cardPadding: '1rem',
    formFieldPadding: '0.5rem',
    formSpacer: '2rem',
    sidebarWidth: '16rem',
    sidebarWidthClosed: '3.75rem',
    footerHeight: '2.5rem',
    siteReportsSearch: '0.2rem'

  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
      }
    }
  }
});

export default theme;
