import { useEffect } from 'react';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getOrgDivTeam, getPermissionSubjects } from 'ducks/auth/selectors';
import { changeCurrentTeam } from 'ducks/auth/actions';

const useFirstTeamIfNotSet = permissions => {
  const { div, team } = useReduxSelector(getOrgDivTeam);
  const permissionSubjects = useReduxSelector(getPermissionSubjects);
  const changeTeam = useReduxAction(changeCurrentTeam);

  useEffect(() => {
    if (!div || !team) {
      const potentialSubjects = permissions
        .map(perm => permissionSubjects?.[perm])
        .reduce((a, b) => a.concat(b));
      const firstSubjectShortID = potentialSubjects[0]?.split('/')?.[2];

      changeTeam(firstSubjectShortID, 'division1');
    }
  }, [div, team, changeTeam, permissionSubjects, permissions]);
};

export default useFirstTeamIfNotSet;
