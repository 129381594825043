import {
  generateFetchDataTypes,
  generateFetchDataActions,
  generatePostDataTypes,
  generatePostDataActions
} from 'ducks/utils';

export const types = {
  login: generateFetchDataTypes('UTTO/AUTH/LOGIN'),
  LOGOUT: 'UTTO/AUTH/LOGOUT',
  changeDetails: generatePostDataTypes('UTTO/AUTH/CHANGE_DETAILS'),
  createAccount: generatePostDataTypes('UTTO/AUTH/CREATE_ACCOUNT'),
  changeCurrentTeam: 'UTTO/AUTH/CHANGE_CURRENT_TEAM',
  changeCurrentDivision: 'UTTO/AUTH/CHANGE_CURRENT_DIVISION',
  openSnackbar: 'UTTO/AUTH/OPEN_SNACKBAR',
  closeSnackbar: 'UTTO/AUTH/CLOSE_SNACKBAR'
};

export const loginActions = generateFetchDataActions(types.login);

export const logout = () => ({
  type: types.LOGOUT
});

export const changeDetailsActions = generatePostDataActions(
  types.changeDetails
);

export const createAccountActions = generatePostDataActions(
  types.createAccount
);

export const changeCurrentTeam = (team, div) => ({
  type: types.changeCurrentTeam,
  payload: { team, div }
});

export const changeCurrentDivision = div => ({
  type: types.changeCurrentDivision,
  payload: { div }
});

export const openSnackbar = (variant, message) => ({
  type: types.openSnackbar,
  payload: { variant, message }
});

export const closeSnackbar = () => ({
  type: types.closeSnackbar
});
