export const getOrgs = state => state?.admin?.orgs;
export const getDivs = state => state?.admin?.divs;
export const getTeams = state => state?.admin?.teams;
export const getMembers = state => state?.admin?.members;
export const getManagers = state => state?.admin?.managers;
export const getUsers = state => state?.admin?.users;

export const getNewOrg = state => state?.admin?.newOrg;
export const getNewDiv = state => state?.admin?.newDiv;
export const getNewTeam = state => state?.admin?.newTeam;
export const getNewUser = state => state?.admin?.newUser;
export const getNewSupportForm = state => state?.admin?.newSupportForm;

export const getUtilityTypes = state => state?.admin?.utilityTypes;
export const getNewUtilityTypeAllocation = state =>
  state?.admin?.newUtilityTypeAllocation;

export const getUnprocessedUploads = state => state?.admin?.unprocessedUploads;
export const getUploads = state => state?.admin?.uploads;
export const getRefreshUnprocessed = state =>
  state?.admin?.refreshUnprocessedUploads;
export const getSingleUpload = state => state?.admin?.singleUpload;
