import { all, select, debounce } from 'redux-saga/effects';
import { types as areaTypes } from 'ducks/area/actions';
import { getOrgDiv } from 'ducks/auth/selectors';
import { fetchDataWorkerSaga } from './util';
import { fetchTeams, fetchStudiesSearch, fetchDamagesSearch } from 'services/api';
import moment from 'moment';

function* getTeams() {
  try {
    const { org, div } = yield select(getOrgDiv);
    yield* fetchDataWorkerSaga(areaTypes.teams, fetchTeams, {
      organization: org,
      division: div
    });
  } catch (err) {
    console.log(err);
  }
}

function* getStudies(action) {
  //console.log(JSON.stringify(action.payload));
  const count = parseInt(action.payload.pageSize);
  const offset = parseInt(action.payload.page) * count;
  try {
    yield* fetchDataWorkerSaga(
      areaTypes.studies,
      fetchStudiesSearch,
      {
        after: moment(action.payload.from).unix(),
        before: moment(action.payload.to).unix(),
        filters: action.payload?.search?.filter || [],
        count: count,
        offset: offset,
        sort_key: action.payload.orderBy,
        sort_direction: action.payload.orderDirection
      },
      {
        //...action.payload,
      },
      res => ({
        ...res,
        body: res.body.map(item => ({
          ...item,
          avg_weighted_score: Math.abs(item.avg_weighted_score),
          damage_count: item.damage_count,
          damages: item.damages,
          user_label: item.user_label.replace(/<.*>/, ''),
        }))
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getDamages(action) {
  //console.log(JSON.stringify(action.payload));
  const count = parseInt(action.payload.pageSize);
  const offset = parseInt(action.payload.page) * count;
  try {
    yield* fetchDataWorkerSaga(
      areaTypes.damages,
      fetchDamagesSearch,
      {
        after: moment(action.payload.from).unix(),
        before: moment(action.payload.to).unix(),
        filters: action.payload?.search?.filter || [],
        count: count,
        offset: offset,
        sort_key: action.payload.orderBy,
        sort_direction: action.payload.orderDirection
      },
      {
        //...action.payload,
      },
      res => ({
        ...res,
        body: res.body.map(item => ({
          ...item,
          avg_weighted_score: item.avg_weighted_score,
          damage_count: item.damage_count,
          date: item.date
        }))
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* sagas() {
  yield all([
    yield debounce(50, areaTypes.teams.REQUEST, getTeams),
    yield debounce(50, areaTypes.studies.REQUEST, getStudies),
    yield debounce(50, areaTypes.damages.REQUEST, getDamages)
  ]);  
}
