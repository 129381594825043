import React from 'react';
import moment from 'moment';
import 'moment-duration-format'; // NOTE: must be imported AFTER Moment.js
import { Avatar } from '@material-ui/core';

import {
  convertMetresToInches,
  convertMetresToMiles,
  convertMetresToFeet,
  convertMetresPerSecondToMilesPerHour
} from 'utils/convertors';
import { getTagValue, TAGKEYS } from 'utils/tags';

export const capitalise = str => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const safeAssetImageUrl = url => {
  if (!url) return '/awaitingImage.jpg';
  return url;
};

export const formatPhoneLink = phone => {
  if (!phone) return '';
  return 'tel:' + phone.replace(/[()]/g, '').replace(' ', '-');
};

export const formatEmailLink = email => {
  if (!email) return '';
  return 'mailto:' + email;
};

export const formatMemberName = member => {
  if (!member) return 'Unknown';
  return `${member.firstname} ${member.lastname}`;
};

export const formatLatLon = (lat, lon, options = {}) => {
  const { decimalPlaces = 4 } = options;

  if (!lat || !lon) return '';

  return lat.toFixed(decimalPlaces) + ',' + lon.toFixed(decimalPlaces);
};

export const formatCurrent = (currentInAmps, options = {}) => {
  const { showUnits = true, decimalPlaces = 1 } = options;

  if (
    currentInAmps === null ||
    currentInAmps === undefined ||
    isNaN(currentInAmps) ||
    currentInAmps === ''
  ) {
    return 'N/A';
  }

  if (Math.abs(currentInAmps) >= 1 || currentInAmps === 0) {
    return currentInAmps.toFixed(decimalPlaces) + (showUnits ? 'A' : '');
  }
  const ma = currentInAmps * 1000;
  if (ma % 1 === 0) {
    return Math.trunc(ma) + (showUnits ? 'mA' : '');
  }
  return ma.toFixed(decimalPlaces) + (showUnits ? 'mA' : '');
};

export const formatAlertCount = (alertCount, options = {}) => {
  const type = options.type;
  if (alertCount >= 1){
    switch (type) {
      case 'total' :
        return <Avatar style={{width:'25px', margin: 'auto', height: '25px',color: '#c51c16', backgroundColor: 'silver', fontSize:'15px'}}>{alertCount}</Avatar>;
      case 'unresolved' :
        return <Avatar style={{width:'25px', margin: 'auto', height: '25px',color: 'white', backgroundColor: '#c51c16', fontSize:'15px'}}>{alertCount}</Avatar>;
      default:
        return ' ';
      }
    }

  return ' ';
};

const metersToImperial = (distanceInMeters) => {
  const inches = convertMetresToInches(distanceInMeters);
  const miles = convertMetresToMiles(distanceInMeters);
  const feet = convertMetresToFeet(distanceInMeters);
  const na = distanceInMeters === 0 ||
             distanceInMeters === null ||
             distanceInMeters === undefined ||
             isNaN(distanceInMeters) ||
             distanceInMeters === ''

  return { inches, miles, feet, na }
}

export const formatDistance = (distanceInMeters, options = {}) => {
  const {
    showUnits = true,
    feetOnly = false,
    longUnits = false,
    decimalPlaces = 2
  } = options;

  const { inches, miles, feet, na } = metersToImperial(distanceInMeters);
  if (na) {
    return 'N/A';
  }

  if (Math.abs(inches) < 1) {
    return '0';
  }

  if (Math.abs(miles) >= 1 && !feetOnly) {
    const units = showUnits ? (longUnits ? ' miles' : 'mi') : '';
    return `${miles.toFixed(decimalPlaces)}${units}`;
  }

  if (Math.abs(feet) >= 3) {
    const unitsFt = showUnits ? (longUnits ? ' feet' : 'ft') : '';
    const unitsIn = showUnits ? (longUnits ? ' inches' : 'in') : '';
    let string = `${Math.trunc(feet)}${unitsFt}`;
    if (!feetOnly) {
      if (longUnits) {
        string += ',';
      }
      string += ` ${(inches % 12).toFixed(0)}${unitsIn}`;
    }
    return string;
  }

  const units = showUnits ? (longUnits ? ' inches' : 'in') : '';
  return `${Math.trunc(inches)}${units}`;
};

export const formatHeight = (distanceInMM) => {
  if (distanceInMM === null) return null;
  const distanceInMeters = distanceInMM / 1000;
  return formatDistance(distanceInMeters, { feetOnly: true });
};

export const formatBufferZone = (distanceInMM, options = {}) => {
  const {
    isVertical = false,
    positioningMode = '',
  } = options;

  if (distanceInMM === null) return null;

  // clamp min displayed accuracy to 1m if not rtk fix
  if (positioningMode !== 'R') distanceInMM = Math.max(distanceInMM, 1000);

  const distanceInMeters = distanceInMM / 1000;
  const { inches, na } = metersToImperial(distanceInMeters);
  if (na) {
    return 'N/A';
  }

  const accuracySaturation = isVertical ? 5 : 4;

  if (inches < accuracySaturation) {
    return '< ' + accuracySaturation + ' inches';
  }

  return formatDistance(distanceInMeters, options);
};

export const formatSpeed = (metresPerSecond, options = {}) => {
  const { showUnits = true, decimalPlaces = 0 } = options;

  if (
    metresPerSecond === null ||
    metresPerSecond === undefined ||
    isNaN(metresPerSecond) ||
    metresPerSecond === ''
  ) {
    return '';
  }

  return (
    convertMetresPerSecondToMilesPerHour(metresPerSecond).toFixed(
      decimalPlaces
    ) + (showUnits ? 'mph' : '')
  );
};

export const humanize = label =>
  label.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, key => key.toUpperCase());

export const humanizeRoles = label =>
  label === 'team_manager' ? 'Team Supervisor' : humanize(label);

export const formatDuration = (durationInSeconds, options = {}) => {
  const { showUnits = true, humanize = false, longUnits = false } = options;

  if (
    durationInSeconds === null ||
    durationInSeconds === undefined ||
    isNaN(durationInSeconds) ||
    durationInSeconds === ''
  ) {
    return '';
  }

  if (durationInSeconds === 0) {
    return 'N/A'
  }

  const duration = moment.duration(Number(durationInSeconds), 'seconds');

  if (humanize) {
    return duration.humanize();
  } else if (!showUnits) {
    return duration.format('h:mm:ss', { forceLength: true });
  } else if (longUnits) {
    return duration.format('h [hours], m [minutes], s [seconds]', {
      largest: 2
    });
  } else {
    return duration.format('h[h] m[m] s[s]', { largest: 2 });
  }
};

export const formatScore = (score, options = {}) => {
  const { showUnits = true, decimalPlaces = 0 } = options;
  if (score === null || score === undefined || isNaN(score) || score === '') {
    return 'N/A';
  }

  return score?.toFixed(decimalPlaces) + (showUnits ? '%' : null);
};

export const formatGain = (gain, options = {}) => {
  const { showUnits = true, decimalPlaces = 0 } = options;
  if (gain === null || gain === undefined || gain === '' || isNaN(gain)) {
    return '';
  }

  return gain.toFixed(decimalPlaces) + (showUnits ? 'dB' : null);
};

export const formatPointType = (tags, options = {}) => {
  const pointType = getTagValue(tags, TAGKEYS.FEATURE_TYPE);

  if (pointType) return capitalise(pointType);

  return '-';
};

export const formatFrequency = (frequency, options = {}) => {
  const { showUnits = true } = options;
  if (
    frequency === null ||
    frequency === undefined ||
    frequency === '' ||
    isNaN(frequency)
  ) {
    return 'N/A';
  }

  return frequency < 1000
    ? frequency.toFixed(0) + (showUnits ? 'Hz' : '')
    : frequency < 1000000
      ? (frequency / 1000)
        .toFixed(2)
        .slice(0, 4)
        .replace(/\.$/, '') + (showUnits ? 'kHz' : '')
      : (frequency / 1000).toFixed(0) + (showUnits ? 'kHz' : '');
};

export const timeAgo = (timestamp, options = {}) => {
  const { unixTimestamp = false } = options;
  if (timestamp === null || timestamp === undefined || timestamp === '') {
    return '';
  }

  return moment.utc(unixTimestamp ? Number(timestamp) : timestamp).fromNow();
};

export const formatDateTime = (timestamp, options = {}) => {
  const {
    dateOnly = false,
    timeOnly = false,
    long = false,
    unixTimestamp = false
  } = options;
  if (timestamp === null || timestamp === undefined || timestamp === '') {
    return '';
  }

  const datetime = moment
    .utc(unixTimestamp ? Number(timestamp) : timestamp)
    .local();

  if (dateOnly) {
    return datetime.format(long ? 'LL' : 'll');
  } else if (timeOnly) {
    return datetime.format(long ? 'LTS' : 'LT');
  } else {
    return datetime.format(long ? 'LLL' : 'lll');
  }
};

export const teamFormatter = (team = '') => {
  return team ? team.split('/')[2] : '';
};

export const formatPositioningMode = (s) => {
  switch (s) {
    case "N":
      return "No fix";
    case "E":
      return "Estimated / dead-reckoning fix";
    case "A":
      return "No Correction Used";
    case "D":
      return "Differential GNSS fix";
    case "F":
      return "RTK float";
    case "R":
      return "RTK fixed";
    default:
      return "-";
  }
}
