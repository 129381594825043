import { useQueryString } from 'hooks';
import { SESSION_FILTERS } from 'config';

const useSessionPerformanceParams = () => {
  // use a query string parameter for session performance
  const [sessionPerformance, setSessionPerformance] = useQueryString(
    'sessionPerformance',
    Object.values(SESSION_FILTERS).map(v => v.toString())
  );

  return [sessionPerformance, setSessionPerformance];
};

export default useSessionPerformanceParams;
