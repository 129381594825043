import {
  createReducer,
  combineReducersWithTeamChangeReset,
  generateFetchDataReducers,
  FETCH_DATA_INITIAL_STATE
} from 'ducks/utils';
import { types } from './actions';
import { types as adminTypes } from 'ducks/admin/actions';

const technicianActivityHistory = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.technicianActivityHistory)
);

const technicianActivityHistogram = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.technicianActivityHistogram)
);

const scoresHistory = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.scoresHistory)
);

const teamAverageScoresHistory = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.teamAverageScoresHistory)
);

const siteReports = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.siteReports)
);

const siteReport = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducers(types.siteReport),
  [adminTypes.newUtilityTypeAllocation.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: { ...state.data, utility_type: action?.payload?.utilityType }
    };
  }
});

const emPoints = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducers(types.emPoints)
);

export default combineReducersWithTeamChangeReset({
  emPoints,
  scoresHistory,
  siteReports,
  siteReport,
  teamAverageScoresHistory,
  technicianActivityHistory,
  technicianActivityHistogram
});
