import { all, select, debounce, put } from 'redux-saga/effects';
import {
  types as technicianTypes,
  siteReportActions,
} from 'ducks/technician/actions';
import { fetchDataWorkerSaga } from './util';
import { getUserId } from 'ducks/auth/selectors';
import {
  fetchSiteReport,
  fetchTechnicianScoresHistory,
  fetchTeamScoresAverage,
  fetchActivityScores,
  fetchActivityHistogram,
  fetchSessionSearch,
} from 'services/api';
import { getOrgDivTeam } from 'ducks/auth/selectors';
import moment from 'moment';
import { overallScore } from 'utils/overallScore';

function* getSiteReports(action) {
  //console.log(JSON.stringify(action.payload));
  const count = parseInt(action.payload.pageSize);
  const offset = parseInt(action.payload.page) * count;
  try {
    let _filters = [];
    _filters = action.payload.search && JSON.parse(action.payload.search)?.map(f => f.filter);
    yield* fetchDataWorkerSaga(
      technicianTypes.siteReports,
      fetchSessionSearch,
      {
        after: moment(action.payload.from).unix(),
        before: moment(action.payload.to).unix(),
        filters: _filters,
        count: count,
        offset: offset,
        sort_key: action.payload.orderBy,
        sort_direction: action.payload.orderDirection
      },
      {
        //...action.payload,
      },
      res => ({
        ...res,
        body: res.body.map(item => ({
          ...item,
          id: item.pgid,
          lat: item.geo_point.lat,
          lon: item.geo_point.lon,
          overall: overallScore(item)
        }))
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getSiteReport(action) {
  const { sessionId, ...payloadRest } = action?.payload;
  if (!sessionId) {
    yield put(siteReportActions.clear());
    return;
  }
  try {
    const userId = yield select(getUserId);
    yield* fetchDataWorkerSaga(
      technicianTypes.siteReport,
      fetchSiteReport,
      { sessionId },
      {
        ...payloadRest,
        userId: userId,
        projection:
          'utilityType,id,userId,teamId,locatorDetails,latlon,startTime,duration,geo,numPoints,numAssets,modalFrequency,minCurrent,location,metrics,distanceWalked,spanDistance,walkingSpeed,activity,overall'
      },
      res => ({ ...res, overall: overallScore(res) })
    );
  } catch (err) {
    console.log(err);
  }
}

function* getScoresHistory(action) {
  try {
    const userId = yield select(getUserId);
    yield* fetchDataWorkerSaga(
      technicianTypes.scoresHistory,
      fetchTechnicianScoresHistory,
      {},
      { ...action.payload, userId: userId },
      res => {
        res.user_scores.forEach(item => {
          item.metrics.overallScore = overallScore(item.metrics, {
            has_underscores: false
          });
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getAverageScores(action) {
  try {
    const { org, div, team } = yield select(getOrgDivTeam);
    yield* fetchDataWorkerSaga(
      technicianTypes.teamAverageScoresHistory,
      fetchTeamScoresAverage,
      {
        org,
        div,
        team,
        ...action.payload
      },
      {},
      res => {
        res.user_scores.forEach(item => {
          item.metrics.overallScore = overallScore(item.metrics, {
            has_underscores: false
          });
        });
        return res;
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getActivityHistory(action) {
  try {
    const userId = yield select(getUserId);
    yield* fetchDataWorkerSaga(
      technicianTypes.technicianActivityHistory,
      fetchActivityScores,
      {
        userId,
        ...action.payload
      }
    );
  } catch (err) {
    console.log(err);
  }
}

function* getActivityHistogram(action) {
  try {
    const userId = yield select(getUserId);
    yield* fetchDataWorkerSaga(
      technicianTypes.technicianActivityHistogram,
      fetchActivityHistogram,
      {
        userId,
        ...action.payload
      }
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* sagas() {
  yield all([
    yield debounce(50, technicianTypes.siteReports.REQUEST, getSiteReports),
    yield debounce(50, technicianTypes.siteReport.REQUEST, getSiteReport),
    yield debounce(50, technicianTypes.technicianActivityHistory.REQUEST, getActivityHistory),
    yield debounce(50, technicianTypes.scoresHistory.REQUEST, getScoresHistory),
    yield debounce(50, technicianTypes.teamAverageScoresHistory.REQUEST, getAverageScores),
    yield debounce(50, technicianTypes.technicianActivityHistogram.REQUEST, getActivityHistogram),
  ]);
}
