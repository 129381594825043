import { useMemo } from 'react';

// List fields in an object that have null as their value.
// For filtering performance scores components.
const useNullValues = object =>
  useMemo(() => {
    if (!object) return [];
    const keys = Object.keys(object);
    return keys?.filter(key => object[key] === null);
  }, [object]);

export default useNullValues;
