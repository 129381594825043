import { useMemo } from 'react';
import useSessionPerformanceParams from './useSessionPerformanceParams';
import { SESSION_FILTERS, SESSION_FILTERS_RANGE } from 'config';
import { calculateOverallScore } from 'components/maps/utils';

const useFilteredSessionReports = (reports = []) => {
  const [
    sessionPerformance,
    setSessionPerformance
  ] = useSessionPerformanceParams();

  const filteredReport = useMemo(
    () =>
      reports.filter(report => {
        const score = calculateOverallScore(report);
        let included = false;
        if (sessionPerformance.includes(SESSION_FILTERS.OK.toString())) {
          if (
            score > SESSION_FILTERS_RANGE.MIN &&
            score < SESSION_FILTERS_RANGE.MAX
          ) {
            included = true;
          }
        }
        if (sessionPerformance.includes(SESSION_FILTERS.PROBLEM.toString())) {
          if (score < SESSION_FILTERS_RANGE.MIN) {
            included = true;
          }
        }
        if (sessionPerformance.includes(SESSION_FILTERS.GOOD.toString())) {
          if (score > SESSION_FILTERS_RANGE.MAX) {
            included = true;
          }
        }
        return included;
      }),
    [reports, sessionPerformance]
  );

  return [filteredReport, sessionPerformance, setSessionPerformance];
};

export default useFilteredSessionReports;
