import { makeStyles } from '@material-ui/styles';

const styles = theme => ({
  foot: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: 'fixed',
    bottom: 0,
    padding: '0.25rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginLeft: '0.5rem'
    }
  }
});

export default makeStyles(styles, { name: 'footerStyles' });
