import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useReduxAction = actionCreator => {
  const dispatch = useDispatch();
  const actionDispatcher = useMemo(
    () => (...args) => dispatch(actionCreator(...args)),
    [actionCreator, dispatch]
  );
  return actionDispatcher;
};

export default useReduxAction;
