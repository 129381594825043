import validate from 'validate.js';

const constraints = {
  email: {
    presence: {
      allowEmpty: false
    },
    email: true
  },
  phone: {
    presence: {
      allowEmpty: false
    },
    format: /[0-9()+.-\s]+/
  },
  password: {
    presence: {
      allowEmpty: false
    },
    length: {
      minimum: 8,
      maximum: 64
    }
  },
  firstname: {
    presence: {
      allowEmpty: false
    },
    length: {
      minimum: 1,
      maximum: 256
    }
  },
  lastname: {
    presence: {
      allowEmpty: false
    },
    length: {
      minimum: 1,
      maximum: 256
    }
  },
  teamname: {
    presence: {
      allowEmpty: false
    },
    length: {
      maximum: 128
    },
    format: {
      pattern: /[a-zA-Z0-9_-]+/,
      message: 'can only be letters, numbers, _, -'
    }
  },
  name: {
    presence: {
      allowEmpty: false
    },
    length: {
      maximum: 256
    },
    format: {
      pattern: /[a-zA-Z0-9]+/,
      message: 'can only be letters and numbers. '
    }
  }
};

const all = fields => validate(fields, constraints);

const single = (name, value) => {
  const fieldsSingle = { [name]: value };
  const constraintsSingle = { [name]: constraints[name] };

  const result = validate(fieldsSingle, constraintsSingle);
  return result ? result[name] : result;
};

export default {
  all,
  single
};
