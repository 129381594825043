import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Login as LoginComponent } from 'utto-react-components';
import Footer from './footer';
import { withTracker } from 'utils';
import { useReduxSelector, useReduxAction, useRouter } from 'hooks';
import { getLogin } from 'ducks/auth/selectors';
import { loginActions } from 'ducks/auth/actions';
import history from 'services/history';
import Logo from 'logo.svg';
import useStyles from 'pages/styles';

const Login = props => {
  const classes = useStyles();
  const { location } = useRouter();
  const [locationError, setLocationError] = useState(location?.state?.error);
  const { error, pending } = useReduxSelector(getLogin);
  const login = useReduxAction(loginActions.request);
  const onForgotPassword = useCallback(
    () => history.push('/account/forgotpassword'),
    []
  );
  const onLogin = useCallback(
    ({ email, password }) => {
      setLocationError(null);
      login({ email: email.toLowerCase(), password });
    },
    [login]
  );

  return (
    <div className={classes.dialogContainer}>
      <Helmet>
        <title>UTTO | Sign in</title>
      </Helmet>
      <LoginComponent
        title=" "
        logo={Logo}
        loading={pending}
        error={error || locationError}
        onSubmit={onLogin}
        onForgotPassword={onForgotPassword}
      />
      <Footer />
    </div>
  );
};

export default withTracker(Login);
