const STORAGE_KEY = 'USER_TOKEN';

export const get = () => JSON.parse(localStorage.getItem(STORAGE_KEY));

export const set = (token, user) =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ token, user }));

export const clear = () => localStorage.removeItem(STORAGE_KEY);

export const update_user = userData => {
  const { token, user } = get();
  set(token, { ...user, ...userData });
};

export default {
  get,
  set,
  clear,
  update_user
};
