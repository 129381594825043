import {
  AmbientLight,
  LightingEffect,
  _CameraLight as CameraLight
} from '@deck.gl/core';

///////////////////////////////////////////////////////////////////////////////
//                             CONSTANT VARIABLES                            //
///////////////////////////////////////////////////////////////////////////////
export const MAPBOX_MAP_STYLE = 'mapbox://styles/mapbox/dark-v9?optimize=true';
export const INITIAL_VIEW_STATE = {
  longitude: -73.999242,
  latitude: 40.73061,
  bearing: 5,
  zoom: 11,
  minZoom: 7,
  maxZoom: 21,
  pitch: 45,
  minPitch: 0,
  maxPitch: 60
};
export const HEX_COLOR_DOMAIN = [-50, 100];
export const HEX_COLOR_RANGE = [
  [250, 251, 245],
  [250, 251, 245],
  [250, 251, 245],
  [250, 30, 0],
  [250, 30, 0],
  //under 40%
  [250, 30, 0],
  [250, 30, 0],
  [200, 20, 0],
  [200, 20, 0],
  //above 40%
  [255, 240, 0],
  [255, 240, 0],
  [220, 180, 20],
  //above 70%
  [102, 187, 106],
  [102, 187, 106],
  [80, 167, 126]
];
export const HEX_COLORS = [[244, 67, 54], [255, 240, 0], [102, 187, 106]];
export const HEX_RADIUS = 35;
export const HEX_ELEVATION_DOMAIN = [60, 60 * 15]; // 1 minute to 15 minutes
export const HEX_ELEVATION_RANGE = [100, 2000];

///////////////////////////////////////////////////////////////////////////////
//                         DYNAMIC SCALING FUNCTIONS                         //
///////////////////////////////////////////////////////////////////////////////
export const HEX_RADIUS_SCALE = x => 1000000 / Math.pow(2, x);
export const HEX_ELEVATION_SCALE = x => 10000 / Math.pow(2, x);

///////////////////////////////////////////////////////////////////////////////
//                3D GRAPHICS MATERIALS AND LIGHTING AND STUFF               //
///////////////////////////////////////////////////////////////////////////////

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1
});

const cameraLight = new CameraLight({
  color: [255, 255, 255],
  intensity: 1.5
});

const lightingEffect = new LightingEffect({
  ambientLight,
  cameraLight
});

export const LIGHTING_EFFECTS = [lightingEffect];

export const HEX_MATERIAL = {
  ambient: 0.64,
  diffuse: 0.64,
  shininess: 40,
  specularColor: [51, 51, 51]
};
