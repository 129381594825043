import { useQueryString } from 'hooks';

const useLocatorFilter = () => {
  const [filteredIds, setFilteredIds] = useQueryString('locators', undefined);
  /* Function to toggle a single locator on/off (may be useful again in future)
  const onToggleLocator = useCallback(
    id =>
      setFilteredIds(
        filteredIds.includes(id)
          ? filteredIds.filter(locatorId => locatorId !== id)
          : [...filteredIds, id]
      ),
    [filteredIds, setFilteredIds]
  );
  */

  return [filteredIds, setFilteredIds];
};

export default useLocatorFilter;
